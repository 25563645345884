<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setToken, getToken } from '@/utils/utils';
export default {
  name: 'app',
  data() {
    return {
      token: '',
    };
  },
  created(){
    //友盟APM性能统计
    const APM = require('@umengfe/apm');
    APM.init({
      // pageFilter: { mode: 'match', rules: [] },
      // pkgList: ['com.uemng.apmios', 'com.umeng.apmandroid'],
      pid:'63466e7f05844627b562e6ca',
    });
  },
  mounted(){
    //U-MiniProgram统计设置
    //集成应用的appKey
    const {aplus_queue} = window;
    let appKey = ''
    if(process.env.VUE_APP_ENV == 'production'){
      appKey = '63a93e0dba6a5259c4d9ed05'//正式服统计appKey
    }else{
      appKey = '6398442688ccdf4b7ea39320'//测试服统计appKey
    }
      console.log("appKey",appKey)
    //集成应用的appKey
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['appKey', appKey]
    });
    /************************以下内容为可选配置内容****************************/
      //sdk提供手动pv发送机制，启用手动pv(即关闭自动pv)，需设置aplus-waiting=MAN;
    //注意：由于单页面路由改变时不会刷新页面，无法自动发送pv，所以对于单页应用，强烈建议您关闭自动PV, 手动控制PV事件的发送时机N": N取值为300-3000之间的整数值 , 所有日志指令在SDK初始化完成后的N毫秒内将被hold在指令队列, 直至N毫秒等待结束；"MAN": 取消自动化PV日志采集. 设置为MAN之后, 所有PV日志均需手动触发, 但其他类型的事件日志不受影响
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-waiting', 'MAN']
    });
    //是否开启调试模式 
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['DEBUG', true]
    });
    // aplus_queue.push({  //设置userid
    //    action: 'aplus.setMetaInfo', 
    //    arguments: ['_user_id', this.$route.params.uid || ''] //值为业务自定义的登录账号ID
    //  });



    //统一挂载到window让ios调用保存cookie中的token
    window.iosSetToken = this.iosSetToken;
    // if(!getToken()){
    //   // let token = window.webkit.messageHandlers.functionGetToken.postMessage()
    //   // this.iosSetToken(JSON.parse(token))
    //   let token = this.$route.params.token
      // setToken('X3CNEqtRFRQKLwcXTmGMOaOPHdjJCZoB')
    //   this.iosSetToken(token)
    // }
  },
  methods: {
    iosSetToken(value){
      console.log("进入methods，获取iosSetToken");
      if(value){
        console.log("value  ===== ", value);
        setToken(value)
        return true
      }else{
        return false
      }
    }
  },
  
};
</script>

<style lang="less">
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  // background: #111111;
}
@font-face {
    font-family: DDINBold; 
    src: url('@/utils/D-DIN-Bold.otf');
}
</style>
