<!-- 序列图loading动画 -->
<template>
  <div v-if="showLoading" class="loading_div">
    <img :src="imgUrl" :style="{ width: width + 'px' }" />
  </div>
</template>

<script>
  export default {
    name: 'loadingAnimation',
    props: {
      show: {
        //显示
        type: Boolean,
        default: false,
      },
      width: {
        type: Number,
        default: 100,
      },
    },
    data() {
      return {
        pngIndex: 1,
        timval: null,
        timeOut: null,
        imgUrl: '',
        showLoading: this.$props.show
      };
    },
    mounted() {
      this.initLoading();
    },
    methods: {
      initLoading() {
        this.pngIndex = 1;
        this.timval = setInterval(() => {
          this.pngIndex++;
          this.imgUrl = require(`/src/assets/LoadingImg/ic_loading${this.pngIndex}@3x.png`)
          if (this.pngIndex == 40) {
            this.pngIndex = 1;
          }
        }, 45);
        this.timeOut = setTimeout( () => {
          if(this.timval){
            this.timval = null;
            this.imgUrl = '';
            clearInterval(this.timval);
            this.showLoading = false
            this.$emit("update:show", false)
          }
        },15000)
      },
    },
    beforedestroy() {
      this.timval = null;
      this.imgUrl = '';
      clearInterval(this.timval);
    },
  };
</script>

<style scoped>
  .loading_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
