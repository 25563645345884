import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
axios.defaults.baseURL = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/json'
import jsCookie from 'js-cookie'
import directive from '@/directive'
import components from '@/components';
import "swiper/swiper.min.css"
import Bridge from './utils/jsbridge.js'
Vue.prototype.$jsbridge = Bridge

import dsBridge from './utils/dsbridge.js'
Vue.prototype.$dsBridge = dsBridge;

// 引入中英语言切换包
import {i18n} from './language/index.js'
Vue.use(i18n)

// import userInfoClient from './utils/queryClient.js' //全局获取APP内容数据
// Vue.prototype.$userInfoClient = userInfoClient

// import Vconsole from 'vconsole'
// if (process.env.VUE_APP_ENV == 'test' || process.env.VUE_APP_ENV == 'development') { // 测试和开发打开，生产不能打开
//   let vConsole = new Vconsole()
//   Vue.use(vConsole)
// }
Vue.config.productionTip = false

Vue.prototype.$cookie = jsCookie; //全局引入cookie
Vue.prototype.$oss_host = 'https://media.chaoduiapp.com/'//'https://media.gzyl163.cn/' //图库地址
Vue.use(Vant);
Vue.use(components);

/**
 * 注册指令
 */
Vue.use(directive)

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let favicon = document.querySelector('link[rel="icon"]')
  // console.log('app', favicon)
  if (favicon !== null) {
    favicon.href = to.name == 'home' || to.name == 'accountRecharge' ? '/faviconAccount.ico' : '/favicon.ico'
  } else {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = to.name == 'home' || to.name == 'accountRecharge' ? '/faviconAccount.ico' : '/favicon.ico'
    document.head.appendChild(favicon)
  }
  next()
})

new Vue({
  i18n,
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
