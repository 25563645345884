//自定义指令
import Vue from 'vue'
 // 按钮防抖重复点击方法指令（使用：在el-button加 v-preventReClick 就行了）
const preventReClick = Vue.directive('preventReClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 1500)
            }
        })
    }
});
 
export { preventReClick }