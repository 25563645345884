const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
// 这是必须要写的，用来创建一些设置
function setupWebViewJavascriptBridge(callback) {
  // Android使用
  if (isAndroid) {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge)
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        () => {
          callback(window.WebViewJavascriptBridge)
        },
        false
      )
    }
    sessionStorage.phoneType = 'android'
  }
  // iOS使用
  if (isiOS) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    var WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
    sessionStorage.phoneType = 'ios'
  }
}
// 注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
setupWebViewJavascriptBridge((bridge) => {
  if (isAndroid) {
    // 初始化
    bridge.init((message, responseCallback) => {
      var data = {
        'Javascript Responds': 'Wee!'
      }
      responseCallback(data)
    })
  }
})
export default {
  // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
  callHandler(name, data, callback) {
    //原方案
    // setupWebViewJavascriptBridge((bridge) => {
    //   bridge.callHandler(name, data, callback)
    // })
    if (isAndroid) {//Android走这里
      callback(eval(`window.app.`+name+`('`+JSON.stringify(data)+`')`))
    }else{
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(name, data, callback)
      })
    }
  },
  // APP调js方法 （参数分别为:js提供的方法名  回调）
  registerHandler(name, callback) {
    if (isAndroid) {//Android走这里
      eval(`document['app_${name}'] = function(data){ callback(data)}`)
    }else{
      setupWebViewJavascriptBridge((bridge) => {
        bridge.registerHandler(name, (data, responseCallback) => {
          callback(data, responseCallback)
        })
      })
    }
  }
}




// //自定义JS注册事件监听 connectWebViewJavascriptBridge 方法名可改
// function connectWebViewJavascriptBridge(callback) {
//   if (window.WebViewJavascriptBridge) {
//       callback(window.WebViewJavascriptBridge)
//   } else {
//       document.addEventListener(
//           'WebViewJavascriptBridgeReady'
//           , ()=> {
//               callback(window.WebViewJavascriptBridge)
//           },
//           false
//       );
//   }
// }

// //注册回调函数，第一次连接时调用 初始化函数 connectWebViewJavascriptBridge和上面一致
// connectWebViewJavascriptBridge(function(jsbridge) {
//   //初始化 必须有 Android 通过 JSBridge 调用 默认JS bridge.init bridgeWebView.send调用
//  jsbridge.init(function(message, callback) {
//      callback({'Javascript Responds': 'Android调用JS初始化方法!'});
//  });
// })

// export default  {
//   registerHandler: function(name, fun) {
//       connectWebViewJavascriptBridge(function(jsbridge){
//           //Android调用js方法：functionInJs方法名称需要保持一致 ，并返回给Android通知
//          jsbridge.registerHandler(name, function(data, responseCallback) {
//               responseCallback(fun(data));
//           });
//       })
//   },
//   callHandler: function(name, data, fun) {
//       connectWebViewJavascriptBridge(function(jsbridge){
//           //JS调用Android方法：接收Android传递过来的数据，并做处理
//          jsbridge.callHandler(name, data, function(data) {
//               fun(data);
//           });
//       })
//   }
// }
