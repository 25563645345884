import * as Vue from 'vue'
// import SelectDialog from '../components/dialog/SelectDialog.vue'
// import SimpleConfirmDialog from '../components/dialog/SimpleConfirmDialog.vue'
// import ConfirmDialog from '../components/dialog/ConfirmDialog.vue'
// import Loading from '../components/Loading.vue'
// import NoticeDialog from '../components/dialog/NoticeDialog.vue'
// import  RoundNoticeDialog from '../components/dialog/RoundNoticeDialog.vue'

import jsCookie from 'js-cookie'
import Bridge from '@/utils/jsbridge.js'

export function _storageSet(key, value) {
  if(value && (key=='token' || key=='language' || key=='os')){
    localStorage.setItem(key, value)
  }else{
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export function _storageGet(key, defaultValue = '') {
  const res = localStorage.getItem(key)
  if(res === 'undefined'){
    return null
  }
  if(res && (key=='token' || key=='language' || key=='os')){
    return res
  }
  if (res && key!='token') {
    return JSON.parse(res)
  }
  return defaultValue
}


export function _copy(val) {
  const textarea = document.createElement('textarea')
  document.body.appendChild(textarea)
  textarea.style.position = 'absolute'
  textarea.style.clip = 'rect(0 0 0 0)'
  textarea.value = val
  textarea.select()
  if (document.execCommand) {
    document.execCommand('copy', true)
    _notice('已复制')
  }
  document.body.removeChild(textarea)
}

//单位转换万亿
export function _formatNumber(num) {
  if (num == 0) { return '0' }
  if (!num) return
  if (num > 100000000) {
    return (num / 100000000).toFixed(1) + '亿'
  } else if (num > 10000) {
    return (num / 10000).toFixed(1) + '万'
  } else {
    return num
  }
}

//三位数加逗号
export function _formatMoney(num) {
  if (num == 0) { return '0' }
  if (!num) return
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num
  }
}

//金币 单位转换 k
export function _formatCoin(num) {
  if (num == 0) { return '0' }
  if (!num) return
  if (num == 1000) {
    return 1 + 'k'
  }else if (num > 1000) {
    return ((num / 1000) | 0) + 'k' //不四舍五入不保留小数
  } else {
    return num
  }
}

export function _dateFormat(val, type) {
  if (!val) return
  if (String(val).length === 10) {
    val = val * 1000
  }
  const d = new Date(Number(val))
  const year = d.getFullYear()
  const m = d.getMonth() + 1
  const mStr = m < 10 ? '0' + m : m
  const day = d.getDate()
  const dayStr = day < 10 ? '0' + day : day
  const h = d.getHours()
  const hStr = h < 10 ? '0' + h : h
  const min = d.getMinutes()
  const minStr = min < 10 ? '0' + min : min
  const sec = d.getSeconds()
  const secStr = sec < 10 ? '0' + sec : sec
  switch (type) {
    case 'Y':
      return year + ''
    case 'M':
      return `${year}-${mStr}`
    case 'M_D':
      return `${mStr}-${dayStr}`
    case 'M_CN':
      return `${year}年${mStr}月`
    case 'D':
      return `${year}-${mStr}-${dayStr}`
    case 'm':
      return `${year}-${mStr}-${dayStr} ${hStr}:${minStr}`
    default:
      return `${year}-${mStr}-${dayStr} ${hStr}:${minStr}:${secStr}`
  }
}

export function _time(time) {
  if (String(time).length === 10) {
    time = time * 1000
  }
  const date = new Date(Number(time))
  const now = new Date()
  const d = now.valueOf() - date.valueOf()
  let str = ''
  if (d < 1000 * 60) {
    str = '刚刚'
  } else if (d < 1000 * 60 * 60) {
    str = `${(d / (1000 * 60)).toFixed()}分钟前`
  } else if (d < 1000 * 60 * 60 * 24) {
    str = `${(d / (1000 * 60 * 60)).toFixed()}小时前`
  } else if (d < 1000 * 60 * 60 * 24 * 2) {
    str = '1天前'
  } else if (d < 1000 * 60 * 60 * 24 * 3) {
    str = '2天前'
  } else if (d < 1000 * 60 * 60 * 24 * 4) {
    str = '3天前'
  } else if (date.getFullYear() === now.getFullYear()) {
    str = _dateFormat(time, 'M_D')
  } else {
    str = _dateFormat(time, 'D')
  }
  return str
}


export function _showLoading() {
  // const app = Vue.createApp({
  //   render() {
  //     // return <Loading />
  //   }
  // })
  // const parent = document.createElement('div')
  // parent.classList.add(...['dialog-ctn'])
  // document.body.append(parent)
  // app.mount(parent)
}

export function _hideLoading() {
  const parent = document.querySelector('.dialog-ctn')
  parent.remove()
}

export function _showSelectDialog(sexList, cb) {
  const remove = () => {
    const parent = document.querySelector('.dialog-ctn')
    parent.classList.replace('fade-in', 'fade-out')
    setTimeout(() => {
      parent.remove()
    }, 300)
  }
  const tempCb = (e) => {
    remove()
    cb(e)
  }
  const app = Vue.createApp({
    render() {
      // return <SelectDialog onCancel={remove} list={sexList} onOk={tempCb} />
    }
  })
  const parent = document.createElement('div')
  parent.classList.add(...['dialog-ctn', 'fade-in'])
  document.body.append(parent)
  app.mount(parent)
}

//确认弹窗 1
export function _showSimpleConfirmDialog(title, okCb, cancelCb, okText, cancelText) {
  if (!cancelCb) {
    cancelCb = () => {}
  }
  const remove = () => {
    const parent = document.querySelector('.dialog-ctn')
    parent.classList.replace('fade-in', 'fade-out')
    setTimeout(() => {
      parent.remove()
    }, 300)
  }
  const tempOkCb = (e) => {
    remove()
    okCb(e)
  }
  const tempCancelCb = (e) => {
    remove()
    cancelCb(e)
  }
  const app = Vue.createApp({
    render() {
      // return (
      //   <SimpleConfirmDialog
      //     onCancel={tempCancelCb}
      //     onDismiss={remove}
      //     title={title}
      //     okText={okText}
      //     cancelText={cancelText}
      //     onOk={tempOkCb}
      //   />
      // )
    }
  })
  const parent = document.createElement('div')
  parent.classList.add(...['dialog-ctn', 'fade-in'])
  document.body.append(parent)
  app.mount(parent)
}

//confirm 确认弹窗2
export function _showConfirmDialog(
  title,
  subtitle,
  subtitleColor,
  okCb,
  cancelCb,
  okText,
  cancelText,
  cancelTextColor
) {
  const remove = () => {
    const parent = document.querySelector('.dialog-ctn')
    parent.classList.replace('fade-in', 'fade-out')
    setTimeout(() => {
      parent.remove()
    }, 300)
  }
  const tempOkCb = (e) => {
    remove()
    okCb && okCb(e)
  }
  const tempCancelCb = (e) => {
    remove()
    cancelCb && cancelCb(e)
  }
  const app = Vue.createApp({
    render() {
      // return (
      //   <ConfirmDialog
      //     onCancel={tempCancelCb}
      //     onDismiss={remove}
      //     title={title}
      //     subtitle={subtitle}
      //     subtitleColor={subtitleColor}
      //     cancelTextColor={cancelTextColor}
      //     okText={okText}
      //     cancelText={cancelText}
      //     onOk={tempOkCb}
      //   />
      // )
    }
  })
  const parent = document.createElement('div')
  parent.classList.add(...['dialog-ctn', 'fade-in'])
  document.body.append(parent)
  app.mount(parent)
}
//通知弹窗，正方形
export function _showNoticeDialog(title, subtitle, subtitleColor, cancelCb, cancelText) {
  const remove = () => {
    const parent = document.querySelector('.dialog-ctn')
    parent.classList.replace('fade-in', 'fade-out')
    setTimeout(() => {
      parent.remove()
    }, 300)
  }
  const tempCancelCb = (e) => {
    remove()
    cancelCb(e)
  }
  const app = Vue.createApp({
    render() {
      // return (
      //   <NoticeDialog
      //     onCancel={tempCancelCb}
      //     onDismiss={remove}
      //     title={title}
      //     subtitleColor={subtitleColor}
      //     cancelText={cancelText}
      //     subtitle={subtitle}
      //   />
      // )
    }
  })
  const parent = document.createElement('div')
  parent.classList.add(...['dialog-ctn', 'fade-in'])
  document.body.append(parent)
  app.mount(parent)
}

//通知弹窗， 圆角边带圆角按钮
export function _showRoundNoticeDialog(title, subtitle, subtitleColor, cancelCb, cancelText,positionBotom) {
  const remove = () => {
    const parent = document.querySelector('.dialog-ctn')
    parent.classList.replace('fade-in', 'fade-out')
    setTimeout(() => {
      parent.remove()
    }, 300)
  }
  const tempCancelCb = (e) => {
    remove()
    cancelCb(e)
  }
  const app = Vue.createApp({
    render() {
      // return (
      //   <RoundNoticeDialog
      //     onCancel={tempCancelCb}
      //     onDismiss={remove}
      //     title={title}
      //     subtitleColor={subtitleColor}
      //     cancelText={cancelText}
      //     subtitle={subtitle}
      //     positionBotom={positionBotom}
      //   />
      // )
    }
  })
  const parent = document.createElement('div')
  parent.classList.add(...['dialog-ctn', 'fade-in'])
  document.body.append(parent)
  app.mount(parent)
}

//弹出提示
export function _notice(val) {
  const div = document.createElement('div')
  div.classList.add('global-notice')
  div.textContent = val
  document.body.append(div)
  setTimeout(() => {
    document.body.removeChild(div)
  }, 1500)
}

export function _no() {
  _notice('未实现')
}






export const setToken = (token) => {
  console.log("token ===== ",token)
  jsCookie.set('token', token, { expires: 1 })
}

export const getToken = () => {
  const token = jsCookie.get('token')
  if (token) return token
  else return false
}

export const setOrderInfo = (orderInfo) => {
  console.log("orderInfo ===== ",orderInfo)
  jsCookie.set('orderInfo', orderInfo, { expires: 1 })
}

export const getOrderInfo = () => {
  const orderInfo = jsCookie.get('orderInfo')
  if (orderInfo) return orderInfo
  else return false
}

//获取app传来截取的路径对key值参数，
export const getAppQueryVariable = (query, variable) => {
  // console.log("路径返回",query)
  if(query){
    var vars = query.split("&"); // 根据“&”切割成数组
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // ['appid','1234567890']
      if(pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
    }
  }
  return(false);
}
//获取当前路径对key值参数，比如getQueryVariable('token')获取token
export const getQueryVariable = (variable) => {
  var query = window.location.href.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
  // console.log("路径返回",query)
  if(query){
    var vars = query.split("&"); // 根据“&”切割成数组
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // ['appid','1234567890']
      if(pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
    }
  }
  return(false);
}

//跳转app内或下载App
export const jumpToApp = (url) => {
  var u = navigator.userAgent //,app = navigator.appVersion;
  // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
  var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    window.location.href = url;
}

// 将时间戳转成指定格式的日期时间字符串
export const timeStampFDateTime = function(dateTimr) {
  if (dateTimr) {
    if (dateTimr < 1000000000000) {
      dateTimr = Number(dateTimr + '000')
    }
    var date = new Date(dateTimr)
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    var d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    var h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    var minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    var second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    return m + '月' + d + '日' // + ' ' + h + ':' + minute + ':' + second
  } else { return '' }
}
// 转成指定格式的日期时间字符串
export const DateTimeDainStr = function(dateTimr) {
  if (dateTimr) {
    if (dateTimr < 1000000000000) {
      dateTimr = Number(dateTimr + '000')
    }
    var date = new Date(dateTimr)
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    var d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    var h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    var minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    var second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    return y + '.' + m + '.' + d // + ' ' + h + ':' + minute + ':' + second
  } else { return '' }
}

// 转成指定格式的日期时间字符串
export const DateTimeDainStrNew = function(dateTimr) {
  if (dateTimr) {
    if (dateTimr < 1000000000000) {
      dateTimr = Number(dateTimr + '000')
    }
    var date = new Date(dateTimr)
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    var d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    var h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    var minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    var second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    return y + '-' + m + '-' + d 
  } else { return '' }
}

//跳转app内或下载App
export const goAppPageUtils = (num, url='', params={}) => {
  let urlList = [
    'op://activity/activityList', 'op://op/recharge', 'op://home/personalHomepage', 
    'op://club/clubList', 'op://club/clubDetail', 'op://college/collegeList', 
    'op://college/collegeDetail?collegeId=xxxx', 'op://club/superClubList', 'op://op/WorldChannel', 
    'op://activity/activityDetail', 'op://my/businessCard', 'op://my/incomeWithdrawal', 
    'op://my/editInfor', 'op://my/myHomepage', 'op://chat/TIM_C2C', 
    'op://chat/TIM_GROUP', 'op://moment/send', 'op://user/popular', 
    'op://home/homepage', 'op://chat/chatHomepage', 'op://vip/openVip',
  ]
  let goUrl = ''
  if(num > 0 && num <= urlList.length){
    goUrl = urlList[num-1]
  }else{
    goUrl = url
  }
  if(params){
    let arr = [];
    for (const key in params) {
      arr.push(`${key}=${params[key]}`)
    }
    goUrl += '?' + arr.join('&')
    console.log(goUrl);
  }
  Bridge.callHandler('goAppRouterAction', {'url': goUrl}, (data) => {
    console.log("goAppRouterAction ====== 回调数据")
  })

  // 1、活动列表 op://activity/activityList
  // 2、充值钻石页面 op://op/recharge
  // 3、用户个人主页 op://home/personalHomepage?userId=xxxx
  // 4、俱乐部列表 op://club/clubList
  // 5、俱乐部详情 op://club/clubDetail?clubId=xxxx
  // 6、学院列表 op://college/collegeList
  // 7、学院详情 op://college/collegeDetail?collegeId=xxxx
  // 8、super俱乐部列表 op://club/superClubList
  // 9、世界频道 op://op/WorldChannel
  // 10、活动详情 op://activity/activityDetail?activityId=xxx
  // 11、我的名片 op://my/businessCard
  // 12、收益提现 op://my/incomeWithdrawal
  // 13、资料编辑页面（自己点击后跳转自己的个人资料编辑页） op://my/editInfor
  // 14、个人主页（自己点击后跳转自己的个人主页） op://my/myHomepage
  // 15、跳转到单聊 op://chat/TIM_C2C?userId=xxxx
  //     跳转官方客服： userid： 100097872（正式）
  //     1632668680074678308（开发环境）
  // 16、跳转到群聊（注：是否自己有加入该群） op://chat/TIM_GROUP?groupId=xxxx
  // 17、跳转发布时刻动态 op://moment/send
  // 18、去人气认证 op://user/popular
  // 19、去到App首页 op://home/homepage
  // 20、去到聊天列表页面 op://chat/chatHomepage
  // 21、跳转开通会员页面 op://vip/openVip
}