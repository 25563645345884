
import preventReClick from './preventReClick/index'
import limitInput from './limitInput/index'

const install = function(Vue) {
  // 按钮防抖指令
  Vue.directive('preventReClick', preventReClick)
  // 输入框限制
  Vue.directive('limitInput', limitInput)
}

export default install
