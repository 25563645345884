<template>
  <van-pull-refresh v-model="refreshingData" @refresh="onRefresh">
    <!-- 下拉提示，通过 scale 实现一个缩放效果 -->
    <template #pulling v-if="laLoading"><loadingAnimation :show="laLoading" /></template>
    <!-- 释放提示 -->
    <template #loosing v-if="laLoading"><loadingAnimation :show="laLoading" /></template>
    <!-- 加载提示 -->
    <template #loading v-if="laLoading"><loadingAnimation :show="laLoading" /></template>
    <!-- immediate-check 是否在初始化时立即执行滚动位置检查 -->
    <van-list
      :loading="loadingData"
      :finished="finishedData"
      @load="onLoad"
      :style="{ 'min-height': height }"
      :immediate-check="false"
      loading-text="加载中..."
      finished-text="- THE END -"
    >
      <slot></slot>
    </van-list>
  </van-pull-refresh>
</template>
<script>
export default {
  name:'vantCommonList',
  props:{
    'refreshing':{
      type: Boolean,
      default: false,
    },
    'loading':{ 
      type: Boolean,
      default: false,
    },
    'finished':{ 
      type: Boolean,
      default: false,
    },
    'height':{
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      laLoading: true,
      refreshingData: this.$props.refreshing,
      loadingData: this.$props.loading,
      finishedData: this.$props.finished,
    };
  },
  watch:{
    refreshing(newval,oldval){
      this.refreshingData = newval
    },
    loading(newval,oldval){
      this.loadingData = newval
    },
    finished(newval,oldval){
      this.finishedData = newval
    },
    //监听finishedData.value，因为finishedData加载完后true，会把loadingData也改变了
    finishedData(newval,oldval){
      this.loadingData = false;
    }
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'overflow: hidden;');
  },
  methods: {
    //下拉刷新
    onRefresh(){
      this.$emit('onRefreshFun');
    },
    //上拉加载
    onLoad(){
      this.$emit('onLoadFun');
    }
  },
  beforeDestroy() {
    //离开该组件页面时去掉body的overflow: hidden
    document.body.removeAttribute('style');
  }
}
</script>
