<template>
  <div class="paly_bac_div"  v-if="showVideo">
    <!-- 全屏播放视频 -->
    <div :class="fullScreen ? 'video_all_heng_bac' : 'video_all_bac'">
      <!-- 竖屏头部 -->
      <div class="video_filter_bac" :style="{'height': topheight}" v-if="!fullScreen">
        <div style="width: 100vw;" :style="{'height': headHeight}"></div>
        <div class="close_icon_div">
          <van-icon name="arrow-left" color="#FFF" size="18px" @click="closeVideo()"/>
          <span v-if="title" class="head_title">{{ title }}</span>
        </div>
      </div>
      <!-- 横屏头部 -->
      <div class="video_filter_heng_bac" v-else>
        <div style="width: 100vw;height:10px"></div>
        <div class="close_icon_div">
          <van-icon name="arrow-left" color="#FFF" size="18px" @click="closeVideo()"/>
          <span v-if="title" class="head_title">{{ title }}</span>
        </div>
      </div>
      <!-- @click="closeVideo()"  -->
      <div :class=" fullScreen ? 'video_bac_div_heng' : 'video_bac_div'">
        <div style="position: relative;">
          <video
            ref="videoRef"
            style="width: 100%; max-height': 100%; object-fit: cover;"
            :src="src"
            :poster="poster"
            :controls="true"
            muted ="true"
            :loop="false"
            controlslist="nofullscreen nodownload noremote footbar noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsinline="true"
            webkit-playsinline="true"
            x5-playsinline
            x-webkit-airplay="allow"
            x5-video-player-type="h5"
            :x5-video-player-fullscreen="true"
          >
            您的浏览器不支持video标签。
            <source :src="src" type="video/ogg" />
            <source :src="src" type="video/mp4" />
            <source :src="src" type="video/webm" />
            <object :data="src" width="auto" height="auto">
              <embed :src="src" width="auto" height="auto" />
            </object>
          </video>
          <img src="@/assets/newUserManualImg/manual_open_video_ioc.png" class="open_video_but"
            v-show="!fullScreen" @click="openVideoAll()">
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'videoPaly',
  props:{
    'show':{ 
      type: Boolean,
      default: false,
    },
    'src':{
      type: String,
      default: '',
    },
    'poster':{
      type: String,
      default: '',
    },
    'title':{
      type: String,
      default: '',
    },
    'topheight':{
      type: String,
      default: '90px',
    },
    'headHeight':{
      type: String,
      default: '44px',
    },
  },
  data() {
    return {
      showVideo: this.$props.show,
      fullScreen: false,//是否全屏
    };
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'overflow: hidden;');
  },
  watch:{
    show(newval,oldval){
      if(newval){
        this.lookVideo()
      }else{
        this.closeVideo()
      }
    },
    showVideo(newval,oldval){
      if(newval){
        this.lookVideo()
      }else{
        this.closeVideo()
      }
    },
  },
  methods: {
    lookVideo(){
      this.fullScreen = false
      if(!this.showVideo){
        this.showVideo = true
        this.$emit('update:show', true)
        this.$nextTick( () => {
          this.$refs.videoRef.play()
        })
        //监听回退事件，回退关闭视频窗口
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL);
          window.addEventListener('popstate', this.closeVideo, false);
        }
      }
    },
    closeVideo(){
      if(this.showVideo){
        this.showVideo = false
        this.$emit('update:show', false)
        removeEventListener('popstate', this.closeVideo, false);
        this.fullScreen = false
        //是否隐藏状态栏
        this.$jsbridge.callHandler('hiddenStatusBarEvent', { hiddenStatusBarEvent: false}, () => {
          console.log("否隐藏状态栏")
        })
      }
    },
    openVideoAll(){
      this.fullScreen = true
      //是否隐藏状态栏
      this.$jsbridge.callHandler('hiddenStatusBarEvent', { hiddenStatusBarEvent: true}, () => {
        console.log("是隐藏状态栏")
      })
    }
  },
  beforeDestroy() {
    document.body.removeAttribute('style');
    removeEventListener('popstate', this.closeShowVideo, false);
  }
}
</script>
<style lang="less" scoped>
video::-webkit-media-controls-fullscreen-button {
   display: none;
}
.paly_bac_div{
  height: 100vh;
  width : 100vw;
  position: fixed;
  top: 0px;
  right: 0;
  background: #000;
  z-index: 8;
}
.video_all_bac{
  height: 100vh;
  width : 100vw;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #000;
  z-index: 9;
  transform: translate(-50%, -50%);
}
.video_all_heng_bac{
  position: absolute;
  width: 100vh;
  height: 100vw;
  background: #000;
  z-index: 9;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%)  rotate(90deg); /*适配 Safari 和 Chrome*/
  -moz-transform: translate(-50%, -50%)  rotate(90deg); /*适配 Firefox*/
  -ms-transform: translate(-50%, -50%)  rotate(90deg); /*适配 IE9*/
  -o-transform: translate(-50%, -50%)  rotate(90deg); /*适配 Opera*/
  transform: translate(-50%, -50%)  rotate(90deg);
	transform-origin: center center; 
  transition: all 0.3s linear;
}

.video_filter_bac{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: transparent;
  z-index: 10;
}

.video_filter_heng_bac{
  position: absolute;
  top: 0;
  right: 0;
  width: 100vh;
  height: 64px;
  z-index: 10;
  background: transparent;
}
.close_icon_div{
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.head_title{
  font-size: 16px;
  color: white;
  font-weight: 500;
  margin-top: -4px;
}


.video_bac_div{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_bac_div_heng{
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open_video_but{
  width: 100px;
  height: 36px;
  position: relative;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
