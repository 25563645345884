//实现多端数据传输
// var dsBridge = require("dsbridge");
import dsBridge from 'dsbridge'
export default{
  callmethod(name, data, callback){
    callback(
      dsBridge.call(name, data,{
        msg: "callSyn"
      })
    )
  },
  registermethod(tag, callback){
    dsBridge.register(tag, callback)
  }

}
