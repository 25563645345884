export default {
    app_name: 'Lucky Live',
    '下拉可以刷新': 'Kéo xuống để làm mới',
    '正在刷新': 'Làm mới',
    '正在加載': 'Đang tải',
    '松开立即刷新': 'Làm mới sau khi phát hành',
    '刷新完成': 'Làm mới hoàn tất',
    '刷新失敗': 'Làm mới không thành công',
    '释放即可刷新...':'Giải phóng là có thể đổi mới......',
    '加载中...':'Đang tải......',
    '最后更新': 'Cập nhật lần cuối',
    '上拉加载更多': 'Kéo lên để tải thêm',
    '释放立即加载': 'Phát hành để tải',
    '正在加载': 'Đang tải',
    '正在刷新': 'Làm mới',
    '加载完成': 'Tải xong',
    '加载失败': 'Tải không thành công',
    '已经全部加载完毕': 'Tất cả đã tải',
    '暂时没有更多了':'Tạm thời không còn nữa.',

    '拉黑': 'Chặn',
    '解除拉黑': 'Bỏ chặn',
    '已拉黑': 'Màu đen',
    '已解除拉黑': 'Danh sách đen đã được gỡ bỏ',
    '立即签到': 'Đăng nhập ngay',
    '已连续签到': 'Đã đăng nhập%1$s ngày',
    '连续签到领取额外奖励': 'Phần thưởng thêm cho việc đăng nhập liên tục',
    '天': 'ngày',
    '美颜初始化异常，无法使用': 'Vẻ đẹp khởi tạo ngoại lệ, không thể sử dụng',
    '提现账户': 'Tài khoản rút tiền',
    '您当前还没设定提现账户': 'Bạn chưa thiết lập tài khoản rút tiền',
    '添加提现账户': 'Thêm tài khoản rút tiền',
    '账户类型': 'Loại tài khoản',
    '添加银行卡': 'Thêm thẻ ngân hàng',
    '请提供正确的银行信息以避免提款失败': 'Vui lòng cung cấp thông tin ngân hàng chính xác để tránh rút tiền thất bại',
    '最小可提现为':'Tối thiểu có thể rút ra là ',
    '最大可提现为':'Tối đa có thể rút ra là ',
    '最多可提现为':'Tối đa có thể trích xuất là ',
    '钻石':'Kim cương',
    '提现方式':'Cách rút tiền mặt',

    '银行名称':'Tên ngân hàng',
    '账户名称':'Tên tài khoản',
    '请输入账户名称':'Vui lòng nhập tên tài khoản',
    '账户号码':'Số tài khoản',
    '请输入账户号码':'Vui lòng nhập số tài khoản',
    '手机号码':'Số điện thoại',
    '请输入手机号码':'Vui lòng nhập số điện thoại di động',
    '验证码':'Mã xác nhận',
    '请输入验证码':'Vui lòng nhập CAPTCHA',
    '获取验证码':'Nhận CAPTCHA',
    '重新发送':'Gửi lại',
    '确定':'Xác định',

    '请输入持卡人姓名': 'Vui lòng nhập tên chủ thẻ',
    '请输入银行卡账号': 'Vui lòng nhập số tài khoản thẻ ngân hàng',
    '请输入银行名称': 'Vui lòng nhập tên ngân hàng',
    '请输入微信账号': 'Vui lòng nhập tài khoản WeChat của bạn',
    '请输入支付宝账号': 'Vui lòng nhập tài khoản Alipay',
    '请输入支付宝账号姓名': 'Vui lòng nhập tên tài khoản Alipay',
    '支付宝': 'Tiền mặt',
    '微信': 'Tài khoản WeChat',
    '银行卡': 'Thẻ ngân hàng',
    '是否要删除该账户？': 'Bạn có muốn xóa tài khoản này không? Chuỗi',
    '提现记录': 'Lịch sử rút tiền',
    '赠送': 'Quà tặng',
    '账户余额': 'Số dư tài khoản',
    '支付宝支付': 'Tiền ảo',
    '微信支付': 'Thanh toán qua WeChat',
    '充值成功': 'Nạp tiền thành công',
    '充值失败': 'Phí không thành công',
    '未安装支付宝': 'Không cài đặt Alipay',
    '未安裝微信': 'WeChat không được cài đặt',
    '未安裝QQ': 'qq không được cài đặt',
    '支付宝未配寘': 'Không cấu hình Alipay',
    '微信未配寘': 'Không cấu hình WeChat',
    'Paypal未配寘': 'Không cấu hình paypal',

    '复制链接': 'Sao chép liên kết',
    '复制成功': 'Sao chép thành công',
    '编辑资料': 'Sửa thông tin',
    '头像': 'Hình đại diện',
    '昵称': 'Biệt danh',
    '修改昵称': 'Thay đổi tên người dùng',
    '修改签名': 'Sửa chữ ký',
    '签名': 'Chữ ký',
    '生日': 'Sinh nhật',
    '性別': 'Giới tính',
    '所在地': 'Địa điểm',
    '我的主播印象': 'Hiển thị điểm neo của tôi',
    '设定头像成功': 'Thiết lập avatar thành công',
    '留下点什么吧': 'Để lại một cái gì đó',
    '请输入昵称': 'Vui lòng nhập nickname',
    '最多可输入20个字': 'Có thể nhập tối đa 20 ký tự',
    '昵称最多8个字': 'Biệt danh có thể dài tới 8 ký tự.',
    '请输入昵称': 'Vui lòng nhập nickname',
    '请输入签名': 'Vui lòng nhập chữ ký',
    '请选择正确的日期': 'Vui lòng chọn ngày chính xác',
    '字数超出限制': 'Vượt quá giới hạn số từ',
    '最新': 'muộn nhất',
    '关注': 'Theo dõi',
    '关注': 'Theo dõi',
    '已关注': 'Theo dõi',

    '你还没有关注任何人': 'Bạn chưa theo dõi ai cả',
    '赶快去关注自己感兴趣的人吧': 'Theo dõi những người bạn quan tâm',
    'TA还没有关注任何人': 'Ta chưa theo dõi ai cả',
    '粉丝': 'Người hâm mộ',
    '获赞': 'Được khen ngợi',
    '你还没有粉丝': 'Bạn chưa có người hâm mộ',
    '完善个人信息会让更多的人关注到你哦': 'Cải thiện thông tin cá nhân của bạn sẽ khiến nhiều người chú ý hơn đến bạn',
    'TA还没有任何粉丝': 'TA chưa có fan hâm mộ nào',
    '忘记密码': 'Quên mật khẩu',
    '立即找回': 'Tìm ngay',
    '选择守护时长': 'Chọn thời gian bảo vệ',
    '选择特权': 'Đặc quyền lựa chọn',
    '立即开通': 'Mở ngay',
    '开通守护': 'Kích hoạt bảo vệ',
    '续费守护': 'Bảo vệ gia hạn',
    '续费守护': 'Bảo vệ gia hạn',
    '我的': 'Của tôi',
    '守护': 'Bảo vệ',
    '本周贡献': 'Đóng góp trong tuần',
    '成为TA的第一个守护': 'Trở thành người giám hộ đầu tiên của mình',
    '快去为喜欢的主播开通守护吧': 'Đi tới và kích hoạt bảo vệ cho neo yêu thích của bạn',
    '您是当前主播的月守护\n守护日期截止到': 'Bạn là người giám hộ hàng tháng của neo hiện tại n Ngày giám hộ kết thúc vào',
    "您是当前主播的年守护\n守护日期截止到": 'Bạn là người giám hộ hàng năm của neo hiện tại n Ngày giám hộ kết thúc vào',
    '年守护专属礼物': 'Quà tặng đặc biệt của năm',
    '你还没有守护哦': 'Bạn chưa được bảo vệ',
    "您为当前主播的年守护\n无法开通7天/月守护": 'Bạn là người bảo vệ hàng năm của neo hiện tại n Không thể kích hoạt người bảo vệ 7 ngày/tháng',
    "您为当前主播的月守护，开通年守护将覆盖您的月守护时长，是否开通？": 'Bạn là người bảo vệ hàng tháng cho neo hiện tại của bạn. Kích hoạt bảo vệ hàng năm sẽ bao gồm thời gian bảo vệ hàng tháng của bạn. Bạn muốn kích hoạt nó',
    '您将花费，为主播开通': 'Bạn sẽ chi%1$s%2$s Kích hoạt máy%3$s',
    '7天守护': '7 ngày bảo vệ',
    '月守护': 'Bảo vệ tháng',
    '年守护': 'Bảo vệ hàng năm',
    '守护榜': 'Danh sách người giám hộ',
    '守护了主播': '%1$s bảo vệ điểm neo',

    '主页': 'Trang chủ',
    '点击查看TA的故事': '"%1$s" cũng nằm trong%2$s,~Nhấp để xem câu chuyện của anh ấy',
    '粉丝，快来围观呀': 'Anh ấy có người hâm mộ%1$s, xem nhanh',
    'Hi~我是主播，快来和我聊天吧。': 'Xin chào~Tôi là neo, hãy đến và trò chuyện với tôi.',
    '[图片]': '[image]',
    '[语音]': '[Giọng nói]',
    '[位置]': '[Vị trí]',
    '[商品]': '[Sản phẩm]',
    '[表情]': '[Biểu tượng cảm xúc]',
    '消息': 'Thông điệp',
    '忽略未读': 'Bỏ qua tin nhắn chưa đọc',
    '已忽略未读消息': 'Tin nhắn chưa đọc bị bỏ qua',
    '暂无未读消息': 'Chưa nhận được tin nhắn chưa đọc',
    '消息发送失败': 'Gửi tin nhắn không thành công',
    '操作频繁': 'Hoạt động thường xuyên',
    '对方暂时拒绝接收您的消息': 'Đối phương tạm thời từ chối nhận tin nhắn của bạn',
    '你还没有收到任何消息': 'Bạn chưa nhận được bất kỳ tin nhắn nào',
    '发送消息…': 'Gửi tin nhắn',
    '图片': 'Hình ảnh',
    '拍摄': 'Chụp ảnh',
    '语音输入': 'Nhập giọng nói',
    '位置': 'Địa điểm',
    '选择图片': 'Chọn hình ảnh',
    '未找到本地图片': 'Không tìm thấy ảnh cục bộ',
    '请选择图片': 'Vui lòng chọn một hình ảnh',
    '搜索地点': 'Địa điểm tìm kiếm',
    '未获取到位置信息': 'Không có thông tin vị trí',
    '地图未加载': 'Bản đồ không tải',
    '未获取到地址': 'Không có địa chỉ',
    '获取位置失败': 'Nhận vị trí không thành công',
    '点击关注，可及时看到对方动态': 'Nhấn theo dõi và bạn có thể xem cập nhật của nhau kịp thời.',
    '关注对方成功': 'Theo dõi thành công lẫn nhau',
    '按住说话': 'Giữ cuộc gọi',
    '松开结束': 'Hủy báo chí kết thúc',
    '请说话…': 'Vui lòng nói',
    '录音时间太短': 'Thời gian ghi quá ngắn',
    '手指上滑，取消发送': 'Vuốt lên để hủy gửi',
    '已取消发送': 'Gửi bị hủy',
    '系统消息': 'Thông điệp hệ thống',
    '暂无系统消息': 'Không có thông báo hệ thống',
    '私聊': 'Tán gẫu',
    '主播印象': 'Hiển thị điểm neo',
    '添加印象': 'Thêm',
    '最多选择三项': 'Chọn tối đa 3 mục',
    '请选择印象': ' Vui lòng chọn ấn tượng',
    '未修改印象': 'Hiển thị không thay đổi',
    '請選擇你對主播的印象': 'Vui lòng chọn ấn tượng của bạn về máy chủ',
    '你收到的主播印象': 'Hiển thị neo bạn nhận được',
    '你还没有收到印象哦': 'Bạn chưa nhận được ấn tượng',
    '取消拍照': 'Hủy chụp ảnh',
    '取消选择': 'Bỏ chọn',
    '取消裁剪': 'Hủy cắt',
    '裁剪失败': 'Cắt không thành công',
    '取消录制': 'Hủy ghi hình',
    '您的登录状态失效，请重新登录！': 'Trạng thái đăng nhập của bạn không hợp lệ, vui lòng đăng nhập lại',
    '登录即代表同意': 'Đăng nhập để đồng ý',
    '服务和隐私条款': 'Điều khoản dịch vụ và quyền riêng tư',
    '其他登录渠道': 'Các phương thức đăng nhập khác',
    'Hi~欢迎体验系统': 'Chào mừng bạn đến với hệ thống',
    '请输入您的手机号': 'số điện thoại',
    '请输入密码': 'Vui lòng nhập mật khẩu',
    '登录中': 'Đăng nhập',
    '立即注册': 'Đăng ký ngay',
    '忘记密码': 'Quên mật khẩu',
    '请输入正确的手机号码': 'Vui lòng nhập số điện thoại chính xác',
    '正在授权登录': 'Đăng nhập ủy quyền',
    '登录成功': 'Đăng nhập thành công',

    '当天观看有效时长已达上限':'Thời gian xem có hạn',
    '手机号码不能为空':'Số điện thoại không được để trống',
    '国际区号不能为空':'Mã quốc tế không được để trống',
    '当前视频已达有效观看时长':'Video hiện tại đã đủ lâu để xem',
    '重复观看当前视频不计时':'Lặp lại xem video hiện tại không đếm giờ',
    '验证码不能为空':'Captcha không được để trống',
    '看视频30秒得现金打款':'Xem video 30 giây tiền mặt',
    '授权失败': 'Giấy phép không thành công',
    '授权取消': 'Hủy ủy quyền',
    '请仔细阅读使用者协定并勾选': 'Vui lòng đọc kỹ Thỏa thuận người dùng và kiểm tra',

    '添加到桌面，快速访问':'Thêm vào Desktop, Truy cập nhanh',
    '添加':'Thêm',    
    '安装教程':'Hướng dẫn cài đặt',
    '点击“分享”图标':'Click vào icon "Share"',
    '点击“添加到主屏幕”选项':'Nhấp vào tùy chọn "Add to Home Screen"',
    '在页面上查找':'Tìm trên trang',
    '添加到主屏幕':'Thêm vào màn hình chính',
    '标记':'Đánh dấu',
    '点击“添加”按钮':'Nhấp vào nút "Thêm"',
    '在主屏幕点击Lucky Live打开应用':'Trên màn hình chính, nhấp vào Lucky Live để mở ứng dụng',

    '点击“更多”图标':'Click vào icon "More"',
    '点击“添加到桌面”选项':'Nhấp vào tùy chọn "Add to Desktop"',
    '资源嗅探':'Đánh hơi tài nguyên',
    '网页翻译':'Dịch trang web',
    '添加到桌面':'Thêm vào màn hình nền',
    '页面查找':'Tìm trang',
    '保存离线网页':'Lưu trang web ngoại tuyến',
    '点击“确定”按钮':'Nhấp vào nút OK',
    '编辑书签':'Sửa Đánh dấu',

    '直播': 'sống',
    '主播': 'Điểm neo',
    '開始直播': 'Bắt đầu phát sóng trực tiếp',
    '房间类型': 'Loại phòng',
    '分享至': 'Chia sẻ với',
    '分享至（左右滑动更多分享）': 'Vuốt sang trái hoặc phải để chia sẻ nhiều hơn',
    '直播封面': 'ảnh bìa',
    '更换封面': 'Thay thế trang bìa',
    '直播标题': 'Tiêu đề trực tiếp',
    '给直播写个标题吧': 'Viết tiêu đề cho live stream',
    '频道': 'Kênh',
    '选择直播频道': 'Chọn kênh trực tiếp',
    '注意选择适合自己的频道。 直播过程中，若运营人员发现选择的频道和直播内容不相符的情况，会调整您的直播频道。': 'Chú ý chọn kênh phù hợp với bạn. Trong quá trình phát sóng trực tiếp, kênh phát sóng trực tiếp của bạn sẽ được điều chỉnh nếu nhà điều hành phát hiện ra rằng kênh đã chọn không phù hợp với nội dung trực tiếp. Chuỗi',
    '请设定房间密码': 'Đặt mật khẩu phòng',
    '请设定收费金额\n（收益以直播结束显示为准）': 'Vui lòng đặt khoản phí n (doanh thu sẽ được hiển thị vào cuối buổi phát sóng)',
    '请设定房间密码': 'Vui lòng đặt mật khẩu phòng',
    '请设定收费金额': 'Vui lòng đặt mức phí',
    '收费金额': 'Chi phí',
    '选择收费': 'Chọn sạc',
    '是否要结束直播？': 'Bạn có muốn kết thúc chương trình không? Chuỗi',
    '美颜': 'Đẹp',
    '翻转': 'Chuyển đổi',
    '闪光灯': 'flash',
    '伴奏': 'Nhạc đệm',
    '分享': 'Chia sẻ',
    '游戏': 'Trò chơi',
    '镜像': 'Gương',
    '您已开启镜像': 'Bạn đã bật mirror',
    '您已关闭镜像': 'Bạn đã tắt mirror',
    '红包': 'phong bì màu đỏ',
    '连麦': 'Liên kết micro',
    '断开连麦': 'Ngắt kết nối micrô',
    '只有后置监视器才能开启闪光灯': 'Chỉ có camera phía sau mới có thể bật đèn flash.',
    '打开闪光灯失败': 'Không thể mở flash',
    '你已经被禁言': 'Bạn đã bị cấm',
    '直播已结束': 'Đã kết thúc',
    '返回首页': 'Trở về trang chủ',
    '收获': 'Thu nhập',
    '观看人数': 'Trình xem',
    '关播中': 'Đóng phát sóng trực tiếp',
    '和大家说些什么': 'Nói điều gì đó',
    '开启弹幕': 'Mở màn hình',
    '请输入房间密码': 'Vui lòng nhập mật khẩu phòng',
    '密码错误': 'Lỗi mật khẩu',
    '播放失败': 'Phát lại không thành công',
    '視頻格式不支持': 'Định dạng video không được hỗ trợ',
    '视频地址包含中文，无法播放': 'Địa chỉ video bao gồm tiếng Trung Quốc và không thể phát',
    '守护': 'Bảo vệ',
    '我点亮了': 'Tôi thắp sáng',
    '进入了直播间': 'Vào phòng trực tiếp',
    '礼物': 'Quà tặng',
    '背包': 'Ba lô',
    '道具': 'Đạo cụ',
    '送': 'Gửi',
    '送了1个': 'gửi 1',
    '送给': 'Quà tặng cho',
    '赠送': 'Quà tặng',
    '连送': 'Gửi',
    '连送': 'Gửi x',
    '靓': 'Đẹp',
    '送出': ' Gửi',
    '收入': 'Thu nhập',
    '都市未设定': 'Thành phố chưa được thiết lập',
    '礼物贡献榜': 'Danh sách quà tặng',
    '踢人': 'đá',
    '永久禁言': 'Lệnh cấm vĩnh viễn',
    '本场禁言': 'Phiên này bị cấm',
    '设为管理': 'Thiết lập để quản lý',
    '取消管理': 'Hủy quản lý',
    '管理员清单': 'Danh sách quản trị viên',
    '是否取消管理員身份？': 'Bạn có chắc chắn muốn hủy%1$s là trạng thái quản trị viên không? Chuỗi',
    '是否解除对该用户的禁言': 'Có nên hủy đăng nhập của người dùng này không',
    '是否解除对该用户的踢出': 'Bỏ chọn người dùng',
    '关闭直播': 'Đóng thời gian thực',
    '封禁直播间': 'Phòng phát sóng trực tiếp',
    '禁用账户': 'Vô hiệu hóa tài khoản',
    '当前管理员': 'Quản trị viên hiện tại',
    '被踢出房间': 'Bị đuổi khỏi phòng',
    '被永久禁言': 'Cấm vĩnh viễn',
    '被本场禁言': 'Cấm truy cập vào trang web này',
    '被设为管理员': 'Đặt làm quản trị viên',
    '被取消管理员': 'Quản trị viên đã hủy',
    '你已经被踢出房间': 'Bạn đã bị đuổi khỏi phòng',
    '直播内容涉嫌违规': 'Nội dung trực tiếp bị nghi ngờ vi phạm',
    '贡献榜': 'Danh sách đóng góp',
    '资料': 'Dữ liệu',
    '主播离开一下，精彩不中断，不要走开哦': 'Người dẫn chương trình rời đi một lúc, sự phấn khích sẽ không bị gián đoạn, đừng rời đi',
    '主播回来了！': 'Neo đã trở lại!',
    '推流失败': 'Đẩy không thành công',
    '监视器开启失败': 'Máy ảnh không thể mở',
    '麦克风开启失败': 'Mic không mở được',
    '暂时没有主播开播': 'Hiện tại không có chương trình phát sóng',
    '赶快开启你的直播吧': 'Bắt đầu phát sóng trực tiếp',
    '你关注的主播没有开播': 'neo bạn theo dõi chưa bắt đầu phát sóng',
    '赶快去看看其他主播的直播吧': 'Xem trực tiếp từ các phát thanh viên khác',
    '赶快去其他频道逛逛吧': 'Xem các kênh khác',
    '暂无视频': 'Không có video',
    '赶快发布自己是视频吧': 'Đăng video của riêng bạn',
    '附近没有主播开播': 'Không có neo gần đó',
    '去首页看看其他主播的直播吧': 'Truy cập trang chủ để xem phát sóng trực tiếp từ các neo khác',
    '你还没有发布过动态': 'Bạn chưa đăng bất kỳ tin nhắn nào',
    '快去分享一下你的心情吧': 'Chia sẻ cảm xúc của bạn',
    '暂无动态': 'Không có tin nhắn',
    '去推荐页看看吧': 'Xem trang giới thiệu',
    'TA没有发布过动态': 'Anh ấy chưa đăng bất cứ điều gì',
    '你还没有发布过动态': 'Bạn chưa đăng bất kỳ tin nhắn nào',
    '去看看其他分类的视频吧～': 'Xem video trong danh mục khác~',
    '你最近没有开过直播': 'Bạn đã không phát sóng trực tiếp gần đây',
    '赶快去开场直播体验下吧': 'Chuyển đến trải nghiệm phát sóng trực tiếp',
    'TA最近没有开过直播': 'Anh ấy không phát sóng trực tiếp gần đây.',
    '请选择直播内容': 'Vui lòng chọn nội dung trực tiếp',
    '关注了主播': 'Theo dõi điểm neo',
    '确定举报？': 'Bạn có chắc chắn muốn báo cáo không? Chuỗi',
    '连麦互动': 'Liên kết micro',
    '结束连麦': 'Kết thúc microphone',
    '允许连麦': 'Cho phép micro',
    '禁止连麦': 'Không có micrô',
    '对方刚开播，请稍后连麦': 'Đối phương vừa mới bắt đầu phát sóng trực tiếp, vui lòng chờ',
    '发起连麦请求': 'Bắt đầu yêu cầu micro',
    '发起PK请求': 'Bắt đầu yêu cầu pk',
    '退出连麦': 'Thoát khỏi microphone',
    '当前主播暂时无法接通': 'Không thể đến điểm neo',
    '对方无响应': 'Không có phản hồi',
    '主播连麦中，请等会儿再试哦': 'Điểm neo đã được kết nối với micrô, vui lòng thử lại sau.',
    '对方主播正在忙': 'Điểm neo khác đang bận',
    '主播拒绝了您的连麦请求': 'Máy chủ từ chối yêu cầu kết nối micrô của bạn',
    '对方主播拒绝了您的连麦请求': 'Anchor từ chối yêu cầu kết nối micrô của bạn.',
    '对方主播拒绝了您的PK请求': 'Anchor từ chối yêu cầu pk của bạn',
    '连麦已断开': 'Mic bị ngắt kết nối',
    '对方主播接受了您的连麦请求，开始连麦': 'Các điểm neo khác chấp nhận yêu cầu kết nối micrô của bạn',
    '对方正在忙碌': 'Đối phương đang bận',
    '主播接受了您的连麦请求，开始连麦': 'Người dẫn chương trình chấp nhận yêu cầu kết nối micrô của bạn',
    '对方主播接受了您的连麦请求，开始连麦': 'Các neo khác đã chấp nhận yêu cầu kết nối micrô của bạn',
    '主播接受了您的PK请求，开始PK': 'neo chấp nhận yêu cầu pk của bạn',
    '连麦推流失败，已退出连麦': 'Không thể đẩy luồng vào micrô, đã thoát khỏi micrô',
    '连麦请求已发送': 'Yêu cầu kết nối micrô đã được gửi',
    'PK请求已发送': 'Yêu cầu pk đã được gửi',
    '对方发起PK': 'Đối phương bắt đầu pk',
    '连麦时无法开启伴奏': 'Không thể mở nhạc đệm khi kết nối với micrô',
    '连麦时需要关闭背景音乐': 'Cần tắt nhạc nền khi kết nối micrô',
    '直播记录': 'Ghi âm trực tiếp',
    '无标题': 'Không có tiêu đề',
    '人看过': 'Số người xem',
    '游戏状态不能进行连麦哦': 'Trạng thái trò chơi không thể kết nối với micrô',
    '连麦状态不能进行游戏哦': 'Không thể chơi trò chơi nếu được kết nối với micrô',
    '主播正在忙碌': 'Điểm neo đang bận',
    '当前正在进行连麦': 'Liên kết micrô hiện đang hoạt động',
    '你已经在PK中': 'Bạn đã ở trong pk',
    '我方': 'của chúng tôi',
    '对方': 'Các bên khác',
    '余额不足，是否要充值？': 'Số dư không đủ, anh có muốn nạp tiền không?',
    '当前在线主播': 'Danh sách trực tuyến hiện tại',
    '对方主播在游戏中': 'Máy chủ khác trong trò chơi',
    '邀请连麦': 'Thư mời',
    '已邀请': 'Đã mời',
    '请输入您要搜索的主播昵称或ID': 'Vui lòng nhập nickname hoặc ID của người dẫn chương trình',
    '暂时没有主播': 'Hiện tại không có máy chủ',
    '没有搜索到相关内容': 'Không tìm thấy gì liên quan',
    'PK时间': 'Thời gian pk',
    '惩罚时间': 'Thời gian xử phạt',

    '首页': 'Trang chủ',
    '附近': 'Gần',
    '动态': 'Động',
    '排行': 'Xếp hạng',
    '商城': 'cửa hàng',
    '我的': 'của tôi',
    '普通房间': 'Phòng thường',
    '付费房间': 'Phòng trả tiền',
    '计时房间': 'Phòng hẹn giờ',
    '密码房间': 'Phòng mật khẩu',
    '大咖榜': 'Danh sách doanh thu',
    '土豪榜': 'Danh sách đóng góp',
    '日榜': 'BXH Ngày',
    '周榜': 'BXH Tuán',
    '月榜': 'BXH Tháng',
    '总榜': 'Tổng số',
    '维护通知': 'Thông báo sửa chữa',
    '虚位以待': 'Chờ trống',
    '暂时空缺': 'Tạm thời trống',
    '立即直播': 'Phát trực tiếp',
    '发布视频': 'Đăng tải video',
    '请填写邀请码': 'Vui lòng điền mã mời',
    '再按一次退出': 'Nhấp lại để thoát',
    '推荐主播': 'Điểm neo được đề xuất',
    '热门主播': 'Trang chủ',
    '全部查看': 'Xem tất cả',
    '旧密码': 'Mật khẩu cũ',
    '请输入旧密码': 'Vui lòng nhập mật khẩu cũ',
    '新密码': 'Mật khẩu mới',
    '请填写新密码': 'Vui lòng điền mật khẩu mới',
    '确认密码': 'Xác nhận mật khẩu',
    '确认新密码': 'Xác nhận mật khẩu mới',
    '立即修改': 'Sửa đổi ngay',
    '重置密码': 'Đặt lại mật khẩu',
    'QQ': 'qq',
    'QQ空间': 'Khu vực QQ',
    '微信': 'Điện thoại di động',
    '朋友圈': 'Vòng tròn bạn bè',
    'Facebook': 'Facebook',
    '推特': 'Twitter',
    '搜索歌曲': 'Tìm bài hát',
    '暂无下载歌曲': 'Bài hát chưa tải về',
    '歌曲下载地址无效': 'Địa chỉ tải bài hát không hợp lệ',
    '歌词未找到': 'Không tìm thấy lyrics',
    '歌曲未找到': 'Bài hát không tìm thấy',
    '音乐': 'Nhạc',
    '配乐': 'Nhạc',
    '音量': 'Âm lượng',
    '暂无音乐': 'Không có nhạc',
    '你还没有收藏任何音乐': 'Bạn chưa thu thập được bất kỳ bản nhạc nào',
    '暂无此类音乐': 'Không có loại nhạc này',
    '去看看其他音乐类型吧': 'Xem thêm các thể loại nhạc khác',
    '$': '$',
    '您拒绝了': 'Bạn đã từ chối quyền của%1$s, hãy đi tới Cài đặt để sửa đổi',
    '档案读写': 'Đọc và ghi file',
    '使用监视器': 'Sử dụng máy ảnh',
    '使用麦克风': 'Sử dụng micrô',
    '使用定位': 'Vị trí sử dụng',
    '读取手机信息': 'Đọc tin nhắn điện thoại',
    '读取应用清单': 'Đọc danh sách ứng dụng',
    '您拒绝了显示悬浮窗的权限，请到设定中修改': 'Bạn đã từ chối quyền hiển thị cửa sổ nổi, hãy đi đến Cài đặt để sửa đổi',
    '收入': 'Thu nhập',
    '可到账金额': 'Số tiền có sẵn',
    '请选择提现账户': 'Vui lòng chọn tài khoản rút tiền',
    '输入要提现的': 'Nhập%1$s số tiền bạn muốn trích xuất',
    '您输入的金额大于可提现金额': 'Số tiền bạn nhập lớn hơn số tiền bạn có thể rút',
    '总数': 'Tổng',
    '可选取': 'Có thể trích xuất%1$s',
    '输入要选取的数量': 'Nhập số',
    '立即提现': 'Rút tiền ngay',
    '注册': 'Đăng ký',
    '请输入您的手机号': 'Vui lòng nhập số điện thoại',
    '请输入验证码': 'Mã xác nhận',
    '请填写密码': 'Vui lòng điền mật khẩu',
    '请确认密码': 'Vui lòng xác nhận mật khẩu',
    '注册并登入': 'Đăng ký và đăng nhập',
    '验证码': 'Mã xác nhận',
    '重新获取': 'Quy tắc',
    '密码不一致': 'Mật khẩu không phù hợp',
    '注册中': 'Đang đăng ký',


    '直播间红包': 'phát sóng trực tiếp phong bì màu đỏ',
    '给当前直播间观众发红包': 'Gửi phong bì màu đỏ cho khán giả trong phòng phát sóng trực tiếp hiện tại',
    '拼手气红包': 'May mắn',
    '平均红包': 'Trung bình',
    '恭喜发财，大吉大利': 'Chúc mừng bạn trở nên giàu có và may mắn',
    '发红包': 'Phong bì màu đỏ',
    '请输入金额': 'Vui lòng nhập số tiền',
    '请输入数量': 'Vui lòng nhập số lượng',
    '个红包': 'phong bì màu đỏ',
    '抢': 'Rob',
    '派发了一个实时红包': 'Phân phối ngay lập tức phong bì màu đỏ',
    '派发了一个延时红包': 'Phân phối phong bì màu đỏ bị trì hoãn',
    
    '查看领取详情': 'Xem chi tiết bộ sưu tập',
    '恭喜你！': 'Xin chúc mừng!',
    '抢到': 'Snatch',
    '的红包': 'phong bì màu đỏ của',
    '已存入「我的」': 'Gửi vào"My"',
    '已领取': 'Nhận tổng%',
    '未抢到': 'Không được lấy',
    '倒数计时结束后可抢': 'Bạn có thể lấy nó sau khi đếm ngược',
    '在直播间发红包啦！ 快去抢哦～': 'Phát phong bì màu đỏ trong phòng phát sóng trực tiếp! Bắt lấy nó',
    '单个金额': 'Số lượng đơn',
    '总金额': 'Tổng giá',
    '推荐': 'Đề xuất',
    '跳过': 'Bỏ qua',
    '点击进入': 'Nhấn để nhập',
    '为你推荐': 'Đề xuất cho bạn',
    '男': 'Nam',
    '女': 'Phụ nữ',
    '设定': 'Cài đặt',
    '退出': 'Đăng xuất',
    '清除中': 'Xóa',
    '缓存已清除': 'Xóa bộ nhớ cache',
    '夜间模式': 'Chế độ ban đêm',
    '声音音效': 'Âm thanh hiệu ứng',
    '意见反馈': 'Góp ý',
    '小窗模式': 'Chế độ cửa sổ nhỏ',
    '语言切换': 'Chuyển đổi ngôn ngữ',
    '搜索': 'Tìm kiếm',
    '搜索内容…': 'Tìm kiếm',
    '请输入您要搜索的昵称或ID': 'Nhập nickname hoặc ID',
    '没有搜索到相关内容': 'Không tìm thấy gì liên quan',
    'QQ': 'qq',
    'QQ空间': 'Khu vực QQ',
    '微信': 'Thư điện tử',
    '朋友圈': 'Bạn bè',
    'facebook': 'facebook',
    'twitter': 'twitter',
    '分享成功': 'Chia sẻ thành công',
    '分享失败': 'Chia sẻ thất bại',
    '分享取消': 'Hủy chia sẻ',

    '版本更新': 'Phiên bản',
    '立即使用': 'Sử dụng ngay',
    '暂不更新': 'Chưa cập nhật',
    '已经是最新版本': 'là phiên bản mới nhất',
    '下载地址无效': 'Địa chỉ tải xuống không hợp lệ',
    '说点什么': 'Nói gì đó',
    '视频下载成功': 'Tải video thành công',
    '视频下载失败': 'Tải xuống video không thành công',
    '没有更多视频': 'Không còn phát video nữa',
    '评论': 'Bình luận',
    '不能给自己的评论点赞': 'Không thể like bình luận của mình',
    '不能回复自己': 'Không thể trả lời chính mình',
    '暂无评论，快来抢沙发吧': 'Chưa có bình luận, hãy đến lấy ghế sofa',
    '回复': 'Trả lời',
    '回复给：': 'Trả lời:',
    '展开更多回复': 'Xem thêm',
    '收起': 'Thu gọn',
    '翻转': 'Sửa đổi',
    '闪光灯': 'tốc biến',
    '监视器打开失败': 'Máy ảnh không mở được',
    '麦克风打开失败': 'Mở micro không thành công',
    '录制失败': 'Bản ghi không thành công',
    '别着急，画面还没出来': 'Chờ một chút, màn hình vẫn chưa xuất hiện',
    '还有录制的任务没有结束': 'Vẫn còn tác vụ ghi chưa hoàn thành',
    '传入的视频路径为空': 'Đường dẫn video đến là trống',
    '版本太低': 'phiên bản quá thấp',
    '腾讯云鉴权失败': 'Chứng nhận đám mây Tencent không thành công',
    '极慢': 'Rất chậm',
    '慢': 'Chậm',
    '正常': 'Bình thường',
    '快': 'Nhanh',
    '极快': 'Tốc độ cực nhanh',
    '视频预处理中…': 'Tiền xử lý video',
    '视频预处理失败': 'Quá trình xử lý video không thành công',
    '视频预处理取消': 'Hủy xử lý video',

    '处理中': 'Xử lý',
    '状态异常，停止编辑': 'Trạng thái bất thường, ngừng chỉnh sửa',
    '选择音乐': 'Chọn nhạc',
    '搜索歌曲名称': 'Tìm kiếm tên bài hát',
    '热门歌曲': 'Bài hát phổ biến',
    '我的收藏': 'Bộ sưu tập',
    '滤镜': 'Bộ lọc',
    '裁剪': 'Cắt',
    '特效': 'Hiệu ứng đặc biệt',
    '截取所需音讯片段': 'Cắt đoạn âm thanh cần thiết',
    '原声': 'âm thanh gốc',
    '请拖拽两侧滑块选择裁剪区域': 'Vui lòng kéo thanh trượt ở cả hai bên để chọn vùng cắt',
    '添加视频描述': 'Thêm mô tả video',
    '确认发布': 'Xác nhận phát hành',
    '视频发布': 'Phát hành video',
    '视频生成中': 'Tạo video',
    '生成视频成功': 'Tạo video thành công',
    '生成视频失败': 'Không thể tạo video',
    '是否放弃发布此条视频': 'Có nên từ bỏ việc đăng video này không',
    '放弃': 'Bỏ qua',
    '未找到本地视频': 'Không tìm thấy video cục bộ',
    '本地视频': 'Video địa phương',
    '视频时长不符合要求': 'Thời lượng video không phù hợp',
    '生成视频封面图失败': 'Không thể tạo ảnh bìa video',
    '请添加视频描述': 'Vui lòng thêm mô tả video',
    '发布中': 'Phát hành',
    '发布成功': 'Đã đăng thành công',
    '上传成功': 'Tải lên thành công, vui lòng chờ xem xét',
    '上传失败': 'Tải lên không thành công',
    '请上传视频': 'Vui lòng upload video',
    '仅保存': 'Chỉ lưu',
    '仅发布': 'Chỉ đăng bài',
    '保存并发布': 'Lưu và xuất bản',
    '重新拍摄': 'Chụp lại',
    '退出': 'Thoát',
    '长按可添加特效': 'Nhấn lâu để thêm hiệu ứng đặc biệt',
    '发布失败': 'Phát hành thất bại',
    '你还没有视频作品': 'Bạn chưa có tác phẩm video nào',
    '赶快去拍摄上传吧': 'Tải lên nhanh lên',
    'TA还没有视频作品': 'Ta chưa có video nào',
    '你赞过的作品都会放到这里': 'Tác phẩm yêu thích của bạn sẽ được đặt ở đây',
    '我的视频': 'Video của tôi',
    '我的喜欢': 'Thích của tôi',
    '选择举报理由': 'Chọn lý do báo cáo',
    '更多详细信息请在说明框中描述（选填）': 'Vui lòng mô tả thêm chi tiết trong hộp mô tả (tùy chọn)',
    '提交': 'Đăng nhập',
    '请选择举报理由': 'Vui lòng chọn lý do báo cáo',
    '举报成功': 'Báo cáo thành công',
    '已经到顶了哦': 'Đã lên đến đỉnh',
    '确定删除上一段视频？': 'Bạn có chắc chắn muốn xóa video cuối không? Chuỗi',
    '是否退出视频编辑': 'Không tham gia chỉnh sửa video',
    '添加': 'Thêm',
    '已添加': 'Đã thêm',
    '接受': 'Chấp nhận',
    '全部': 'Tất cả',
    '相册': 'Đĩa đơn',
    '取消': 'Hủy bỏ',
    '拍照': 'Hình ảnh',
    '确定': 'Xác định',
    '请输入内容': 'Vui lòng nhập nội dung',
    '选择': 'Chọn',
    '选择档案': 'Chọn tập tin',
    '无法关注自己哦': 'Không thể theo dõi chính mình',
    '提示': 'Gợi ý',
    '下载': 'Tải về',
    '删除': 'Xóa',
    '结束': 'Kết thúc',
    '热门': 'Phổ biến',
    '网络请求失败': 'Yêu cầu mạng không thành công',
    '请检查网络连接后重试': 'Vui lòng kiểm tra kết nối mạng và thử lại',
    '加载中': 'Đang tải',
    '位置': 'Địa điểm',
    '没有更多数据': 'Không thêm dữ liệu',
    '暂无数据': 'Không có dữ liệu',
    '附近': 'Gần đó',
    '万': 'W',
    '请稍等': 'Vui lòng chờ',
    '私信': 'Thư riêng',
    '举报': 'Báo cáo',
    '重试': 'Thử lại',
    '拒绝': 'Từ chối',
    '保存': 'Lưu lại',
    '发送': 'Gửi',
    '对不起': 'Không phù hợp',
    '视频': 'băng hình',
    '人': 'người',
    '个': 'Độc lập',
    '数量': 'Số lượng',
    '下一步': 'Tiếp theo',
    '上传': 'Tải lên',
    '我的直播间': 'Phòng trực tiếp',
    '我的房间': 'Phòng',
    '管理员': 'Quản trị viên',
    '拉黑用户': 'Chặn người dùng',
    '禁言用户': 'Cấm người dùng',
    '禁言用户列表': 'Danh sách người dùng bị cấm',
    '的直播间': 'là phòng trực tiếp',
    '解除禁言': 'Hủy lớp',
    '拉黑用户列表': 'Danh sách người dùng bị chặn',
    '没有被禁言用户': 'Không có người dùng bị cấm',
    '没有被拉黑用户': 'Không có người dùng nào bị chặn',
    '你还不是管理员': 'Bạn chưa phải là quản trị viên',
    '解除拉黑': 'Bỏ chặn',
    '喜中': 'Thắng',
    '邀请码': 'Mã mời',
    '恭喜': 'Chúc mừng',
    '幸运礼物说明': 'Mô tả món quà may mắn',
    '道具礼物说明': 'Prop Quà tặng Description',
    '当前奖池等级': 'Mức thưởng hiện tại',
    '当前奖池金额': 'Số tiền thưởng hiện tại',
    'Lv.': 'Lv.',
    '发送弹幕，需要到达': 'Để gửi màn hình, bạn cần đạt đến cấp độ%1$s',
    '发言，需要到达': 'Để nói chuyện, bạn cần phải đạt đến mức%1$s',
    '开定位': 'Mở vị trí',
    '坚决关闭': 'Đóng vững',
    '关闭定位，直播不会被附近的人看到，直播间人数可能会减少，确认关闭吗？': 'Định vị gần nơi mọi người không thể xem trực tiếp và số lượng người trong phòng trực tiếp có thể giảm. Bạn có chắc chắn muốn đóng nó không?',
    '火星': 'Sao Hỏa',
    '道具': 'Trang chủ',
    '明细': 'Chi tiết',
    '储值': 'Nạp điện',
    '个性签名': 'Chữ ký',
    '个人信息': 'Thông tin cá nhân',
    '请选择支付方式': 'Chọn phương thức thanh toán',
    '支出': 'Chi phí',
    '收入': 'Thu nhập',

    '《用户充值协议》': ' Thỏa thuận nạp tiền người dùng',
    '已阅读并同意': 'Đọc và đồng ý với',
    '请选择充值金额': 'Chọn số lượng',
    '未选中支付方式': 'Phương thức thanh toán không được chọn',
    '我的': 'của tôi',
    '确认支付': 'Xác nhận thanh toán',
    '支付方式': 'Phương thức thanh toán',
    '立即支付': 'Trả tiền ngay bây giờ',
    '使用者等级': 'Cấp độ người dùng',
    '主播等级': 'Mức neo',
    '连接已断开，请重新开播': 'Kết nối bị ngắt, vui lòng khởi động lại chương trình phát sóng',
    '空空如也': 'trống',
    '游戏规则': 'Luật chơi',
    '中奖记录': 'Kỷ lục chiến thắng',
    '转': 'Xoay lần',
    '获得的礼物将存放在礼物背包中': 'Quà tặng nhận được sẽ được lưu trữ trong ba lô quà tặng',
    '开启购物车': 'Mở giỏ hàng',
    '关闭购物车': 'Đóng giỏ hàng',
    '淘宝商品': 'Hàng hóa trên Taobao',
    '商城小程序': 'Trung tâm thương mại applet',
    '商品连结': 'Liên kết sản phẩm',
    '商品名称（最多可输入20个字）': 'Tên sản phẩm (nhập tối đa 20 ký tự)',
    '原价': 'Giá gốc',
    '现价': 'Giá hiện tại',
    '确认添加商品': 'Xác nhận thêm sản phẩm',
    goods_tip_6: 'Giới thiệu sản phẩm (nhập tối đa 50 ký tự)',
    goods_tip_7: 'Hình ảnh sản phẩm',
    goods_tip_8: 'Đã xem:%1$s lần',
    goods_tip_9: '(Nhập ID sản phẩm tương ứng vào applet sẽ tự động nhận được thông tin sản phẩm)',
    goods_tip_10: 'Tên sản phẩm',
    goods_tip_11: 'Giới thiệu sản phẩm',
    goods_tip_12: 'Mã sản phẩm',
    goods_tip_13: 'Nhận thông tin sản phẩm',
    goods_tip_14: 'Xem chi tiết sản phẩm',
    goods_tip_16: 'Liên kết quảng cáo không hợp lệ',
    goods_tip_17: 'Hàng hóa đang bán',
    goods_tip_18: 'Chuyển đến',
    goods_tip_19: 'Hiện tại không có sản phẩm nào để bán',
    goods_tip_20: 'Tất cả sản phẩm',
    goods_tip_21: 'Thêm sản phẩm',
    goods_tip_22: 'Đã xóa',

    goods_tip_23: 'Hiện tại không có sản phẩm nào được bán n Nhấp vào góc trên bên phải để thêm sản phẩm vào giỏ hàng',
    goods_tip_24: 'Vui lòng nhập tên sản phẩm',
    goods_tip_25: 'Không có sản phẩm nào trong cửa hàng của bạn n Vui lòng thêm sản phẩm vào cửa hàng trước',
    goods_tip_26: 'Hàng hóa đang bán',
    goods_tip_27: 'Đi mua sắm',
    goods_tip_28: 'Đề xuất sản phẩm',
    goods_tip_29: 'Đi mua',
    goods_tip_30: 'Đã xóa',
    goods_tip_31: 'Sản phẩm liên quan',
    goods_tip_32: 'Xem chi tiết sản phẩm',
    goods_tip_32_1: 'Xem chi tiết',
    goods_tip_32_2: 'Liên kết quảng cáo không hợp lệ',
    goods_tip_33: 'Danh sách lại',
    goods_tip_34: 'Sản phẩm này sẽ không còn xuất hiện trong cửa hàng sau khi xóa Chuỗi',
    goods_tip_35: 'Người dùng sẽ không thể mua sản phẩm này sau khi sản phẩm này bị loại bỏ Chuỗi',
    goods_tip_36: 'Vui lòng nhập id sản phẩm',
    goods_tip_37: 'Quản trị xóa',
    goods_tip_38: 'Đã xóa',
    '展示': 'Hiển thị',
    '所有照片': 'Tất cả ảnh',
    '完成': 'Hoàn thành',
    '拍摄': 'Chụp ảnh',
    '最多选': 'Bạn có thể chọn tối đa%1$s ảnh',
    '发布动态': 'Cập nhật',
    '发布': 'Xuất bản',
    '分享你的想法…': 'Chia sẻ suy nghĩ của bạn',
    '所在位置': 'Địa điểm',
    '预览': 'Xem trước',
    '我的动态': 'Động',
    '所在位置': 'Địa điểm',
    '不显示位置': 'Không hiển thị vị trí',
    '录音': 'Ghi âm',
    '点击可录音': 'Nhấp vào ghi âm',
    '点击可暂停': 'Nhấn tạm dừng',
    '请先录音': 'Vui lòng ghi âm trước',
    '录音结束': 'Kết thúc ghi âm',
    '使用视频': 'Sử dụng video',
    '删除视频': 'Xóa video',
    '确定放弃当前编辑内容？': 'Bạn có chắc chắn rằng bạn muốn bỏ nội dung chỉnh sửa hiện tại không',
    '点赞': 'Đã thích',
    '待稽核': 'Đang được xem xét',
    '失败':'Thất bại',
    '未通过': 'Thất bại',
    '说说你的想法…': 'Hãy cho tôi biết bạn nghĩ gì',
    '动态详情': 'Chi tiết động',
    '暂无评论，快来抢沙发吧': 'Chưa có bình luận, hãy lấy ghế sofa',
    '全部评论': 'Tất cả bình luận',
    '\@TA': '\@ta',
    '的直播間送出': '%1$s Gửi%3$s trong phòng phát sóng%2$s',
    '账号已被封禁': 'Tài khoản bị cấm',
    '封禁说明：': 'Mô tả lệnh cấm:',
    '封禁时长：': 'Thời lượng chặn:',
    '知道了': 'know',
    '视频分类': 'Danh mục video',
    '所有分类': 'Tất cả danh mục',
    '选择视频分类': 'Chọn danh mục video',
    '请选择视频分类': 'Vui lòng chọn danh mục video',
    '发送了': 'Quà tặng prop',
    '礼物道具':'đã được gửi',
    '下一个': 'Tiếp theo',
    '本房间为密码房间，请输入密码': 'Phòng này là phòng mật khẩu, vui lòng nhập mật khẩu.',
    '本房间为收费房间，需要支付': 'Phòng này là phòng trả phí, bạn phải trả%1$s%2$s',
    '本房间为计时房间，每分钟需要支付': 'Đây là phòng hẹn giờ và cần%1$s%2$s mỗi phút',
    '已经是最后一个': 'Đã là cuối cùng',
    '重拍': 'Chụp lại',
    '使用视频': 'Sử dụng video',
    '云豹小店': 'Cửa hàng báo mây',
    '浏览记录': 'Lịch sử duyệt web',
    '我的地址': 'Địa chỉ của tôi',
    '账户余额': 'Số dư tài khoản',
    '我的订单': 'Lệnh của tôi',
    '查看全部订单': 'Xem tất cả đơn đặt hàng',
    '待付款': 'Đang chờ thanh toán',
    '待发货': 'Đang chờ giao hàng',
    '待收货': 'Chờ nhận',
    '待评估': 'Được đánh giá',
    '退款': 'Hoàn tiền',
    '我要开店': 'Tôi muốn mở một cửa hàng',
    '开小店，来赚钱': 'Mở một cửa hàng nhỏ để kiếm tiền',
    '开通小店': 'Mở cửa hàng',
    '信息确认': 'Xác nhận thông tin',
    '账号ID': 'ID tài khoản:',
    '姓名：': 'Tên:',
    '身份证号：': 'Số chứng minh thư:',
    '1.请确认上述账号ID、姓名、身份证号为本人信息。 若非本人信息请停止操作。': '1. Vui lòng xác nhận rằng ID tài khoản, tên và số ID trên là thông tin của riêng bạn. Nếu đó không phải là thông tin của riêng bạn, hãy ngừng hoạt động',
    '2.您同意授权本平台公司获取并保存账号ID、姓名、身份证号个人信息用于身份认证备案。': '2. Bạn đồng ý ủy quyền cho công ty nền tảng để có được và lưu giữ thông tin cá nhân như ID tài khoản, tên, số ID để xác thực và nộp hồ sơ. Chuỗi',
    '3.您承诺通过本平台小店开展的经营活动为根据法律法规规定不需要办理市场主体登记的经营行为。': '3. Bạn cam kết rằng các hoạt động kinh doanh được thực hiện thông qua cửa hàng nền tảng này là các hoạt động kinh doanh mà theo quy định của pháp luật không yêu cầu đăng ký chủ thể thị trường. Chuỗi',
    '经营类目': 'Danh mục doanh nghiệp',
    '请选择': 'Vui lòng chọn',
    '经营者联系方式': 'Thông tin liên hệ',
    '连络人': 'Liên hệ',
    '手机号': 'Số điện thoại di động',
    '所在地区': 'Khu vực',
    '详细地址': 'Địa chỉ',
    '客服信息': 'Dịch vụ khách hàng',
    '（不填默认设置为连络人手机号）': '(để trống thông tin liên lạc)',
    '客服电话': 'Số điện thoại dịch vụ khách hàng',
    '退货信息': 'Trả về thông tin',
    '（不填默认设置为经营地址、电话信息）': '(để trống thông tin toán tử)',
    '收货人': 'Người nhận hàng',
    '收货人手机号': 'Số điện thoại di động của người nhận hàng',
    '资质证明': 'Giấy chứng nhận',
    '营业执照': 'Giấy phép kinh doanh',
    '其他证件': 'Tài liệu khác',
    '缴纳保证金': 'Thanh toán tiền gửi',
    '提交': 'Bài viết',
    '请谨慎选择，主营类目设定成功后将不可更改': 'Vui lòng chọn cẩn thận, không thể thay đổi thể loại chính sau khi thiết lập thành công',
    '选择主营类目': 'Chọn danh mục chính',
    '经营类目设定': 'Thiết lập danh mục doanh nghiệp',
    '主营类目最多可选择三项': 'Bạn có thể chọn tối đa ba danh mục chính',
    '请选择经营类目': 'Vui lòng chọn loại hình kinh doanh',
    '已选择': 'Đã chọn',
    '开店保证金': 'Ký quỹ mở cửa hàng',
    '需要缴纳金额': 'Số tiền cần thanh toán',
    '保证金说明': 'Mô tả ký quỹ',
    '1、保证金由商户交由平台暂时保管，用于约束商户行为，保障消费者权益。': '1。 Nền tảng tạm thời không thu tiền thế chấp từ thương gia để ràng buộc hành vi của thương gia, bảo vệ quyền lợi của người tiêu dùng. Chuỗi',
    "2、用户撤销时，可申请退还保证金。": '2。 Khi người dùng hủy, người đó có thể yêu cầu hoàn lại tiền đặt cọc.',
    "3、当用户开通店铺后，若存在欺骗消费者、售卖假冒伪劣产品等一切违反国家法律法规以及平台规定的等行为，平台有权强制关闭店铺，保证金不予退还。": '3。 Sau khi người dùng mở cửa hàng, nếu có hành vi lừa dối người tiêu dùng, bán sản phẩm giả và kém chất lượng và các hành vi vi phạm pháp luật và quy định của nhà nước và các quy định của nền tảng, nền tảng có quyền buộc đóng cửa hàng, tiền đặt cọc không được hoàn lại',
    '4、店铺保证金最终解释权归平台所有。': '4。 Nền tảng bảo lưu quyền giải thích cuối cùng về tiền đặt cọc của cửa hàng',
    '确认缴纳': 'Xác nhận thanh toán',
    '已缴纳保证金': 'Tiền gửi đã thanh toán',
    '请输入姓名': 'Vui lòng nhập tên của bạn',
    '请输入身份证号': 'Vui lòng nhập số ID của bạn',
    '请输入经营者姓名': 'Vui lòng nhập tên của toán tử',
    '请输入经营者手机号': 'Vui lòng nhập số điện thoại của nhà cung cấp dịch vụ',
    '请输入经营者所在区域': 'Vui lòng nhập khu vực của nhà điều hành',
    '请输入经营者详细地址': 'Vui lòng nhập địa chỉ chi tiết của nhà điều hành',
    '请设定营业执照': 'Vui lòng thiết lập giấy phép kinh doanh',
    '请先缴纳保证金': 'Vui lòng đặt cọc trước',
    '信息稽核中…': 'Thông tin đang được xem xét',
    '身份信息稽核未通过': 'Kiểm tra thông tin nhận dạng không thành công',
    '重新认证': 'Chứng nhận lại',
    '3个工作日内会有稽核结果，请耐心等待': 'Kết quả đánh giá sẽ được công bố trong vòng 3 ngày làm việc, vui lòng kiên nhẫn',
    '（卖家端）': '(Người bán)',
    '暂无商品': 'Chưa có sản phẩm',
    '综合分': 'Điểm số',
    '账户金额（元）': 'Số dư tài khoản ($)',
    '累计收入（元）': 'Thu nhập tích lũy ($)',
    '订单管理': 'Quản lý đơn hàng',
    '添加商品': 'Thêm sản phẩm',
    '商品管理': 'Quản lý hàng hóa',
    '地址管理': 'Quản lý địa chỉ',
    '选择商品类别': 'Chọn danh mục sản phẩm',
    '商品类别': 'Danh mục sản phẩm',
    '请选择': 'Vui lòng chọn',
    mall_080: 'Tiêu đề sản phẩm',
    mall_081: 'Từ khóa sản phẩm, trong vòng 15 từ',
    mall_082: 'Sản phẩm video',
    mall_083: 'Hình ảnh sản phẩm',
    mall_084: 'Chi tiết sản phẩm',
    mall_085: 'Đề xuất mô tả chi tiết về sản phẩm bằng mô tả chi tiết, thông số kỹ thuật, phù hợp, hướng dẫn mua, v.v., không quá 300 từ',
    mall_086: 'Hình ảnh chi tiết',
    mall_087: 'Đặc tả biên tập',
    mall_088: 'Tối đa 15 ký tự',
    mall_089: 'Thông số kỹ thuật',
    mall_090: 'Hàng tồn kho',
    mall_091: 'Tối đa 999999 miếng',
    mall_092: 'Đơn giá ($)',
    mall_093: 'Tối thiểu $1.00',
    mall_094: 'Tải ảnh lên',
    mall_095: 'Thông số kỹ thuật mới',
    mall_096: 'Thiết lập vận chuyển',
    mall_097: 'Miễn phí vận chuyển',
    mall_098: 'Gửi đánh giá',
    mall_099: 'Xóa đặc tả',
    mall_100: 'Phí vận chuyển ($)',
    mall_101: 'Vui lòng chọn danh mục sản phẩm',
    mall_102: 'Vui lòng nhập tiêu đề sản phẩm',
    mall_103: 'Vui lòng nhập chi tiết sản phẩm',
    mall_104: 'Vui lòng nhập bưu phí',
    mall_105: 'Vui lòng nhập tên đặc tả',
    mall_106: 'Vui lòng nhập hàng tồn kho',
    mall_107: 'Vui lòng nhập đơn giá',
    mall_108: 'Vui lòng đặt hình ảnh sản phẩm',
    mall_109: 'Đang bán',
    mall_110: 'Xem',
    mall_111: 'Đã xóa',
    mall_112: 'Giá và hàng tồn kho',
    mall_113: 'Đã xóa',
    mall_114: '%1$s Sản phẩm đã bán',
    mall_115: 'Bạn chưa có sản phẩm nào liên quan',
    mall_116: 'Sửa đổi',
    mall_117: 'Đang xem xét',
    mall_118: 'Lên kệ',
    mall_119: 'Xem trước sản phẩm',
    mall_120: 'Chi tiết sản phẩm',
    mall_121: 'Chọn',
    mall_122: 'Vui lòng chọn Specifications',
    mall_123: 'Dịch vụ',
    mall_124: 'Tiền gửi đã thanh toán',
    mall_125: 'Đảm bảo chất lượng',
    mall_126: 'Đến cửa hàng',
    mall_127: 'Tổng doanh thu',
    mall_128: 'Chất lượng sản phẩm',
    mall_129: 'Thái độ phục vụ',
    mall_130: 'Dịch vụ hậu cần',
    mall_131: 'Đánh giá hàng hóa',
    mall_132: 'Chi tiết sản phẩm',
    mall_133: 'Chưa nhận được bình luận',
    mall_134: 'Chưa có chi tiết',
    mall_135: 'Mua ngay',
    mall_136: 'Cửa hàng',
    mall_137: 'Dịch vụ',
    '个人主页': 'Trang chủ',
    file_not_exist: 'Tập tin không tồn tại',
    mall_140: 'Thông tin cơ bản',
    mall_141: 'Chứng chỉ',
    mall_142: 'Xem chứng chỉ',
    mall_143: 'Xác thực tên thật',
    mall_144: 'Đã xác minh',
    mall_145: 'Tiền gửi lưu trữ',
    mall_146: 'Trả tiền',
    mall_147: 'Chi tiết lưu trữ',
    mall_148: 'Trả về quản lý địa chỉ',
    mall_149: 'Địa chỉ giao hàng',
    mall_150: 'Vui lòng nhập tên của bạn',
    mall_151: 'Vui lòng nhập số điện thoại liên lạc của bạn',
    mall_152: 'Vui lòng nhập khu vực của bạn',
    mall_153: 'Vui lòng nhập địa chỉ',
    mall_154: 'Thêm địa chỉ giao hàng',
    mall_155: 'Chưa có địa chỉ giao hàng',
    mall_156: 'Địa chỉ của tôi',
    mall_157: 'Lưu và sử dụng',
    mall_158: 'Tên',
    mall_159: 'Số điện thoại',
    mall_160: 'được đặt làm địa chỉ mặc định',
    mall_161: 'Mặc định',
    mall_162: 'Địa chỉ giao hàng',
    mall_163: 'Bạn có chắc chắn muốn xóa',
    mall_164: 'Nhập cửa hàng',
    mall_165: 'Tổng số%1$s mục',
    mall_167: 'Sản phẩm đang bán',
    mall_168: '%1$s miếng',
    manage: 'Quản lý',
    mall_169: 'Lịch sử duyệt web',
    mall_170: 'Chọn tất cả',
    mall_171: 'Không có lịch sử duyệt web gần đây',
    mall_172: 'Bảo hành dịch vụ',
    mall_173: 'Tiền gửi đã thanh toán',
    mall_174: 'Người bán đã trả một khoản tiền gửi cho nền tảng sẽ được sử dụng để bảo vệ quyền của người mua khi có tranh chấp trong giao dịch',
    mall_175: 'Thương gia đã nộp giấy phép kinh doanh, trình độ chuyên môn và các chứng chỉ liên quan khác lên nền tảng này.',
    mall_176: 'Mua ngay',
    mall_177: 'Còn hàng:%1$s',
    mall_178: 'Đã chọn:%1$s',
    mall_179: 'Chi phí vận chuyển:%1USD',
    mall_180: 'Bài viết',
    mall_181: 'Miễn phí vận chuyển',
    mall_182: 'Tổng số',
    mall_183: 'Thông điệp của người mua',
    mall_184: 'Đề nghị xác nhận với người bán trước khi gửi tin nhắn',
    mall_185: 'Phải thanh toán:',
    mall_186: 'Gửi đơn đặt hàng',
    mall_187: 'Xác nhận đơn hàng',
    mall_188: 'Thông số kỹ thuật:%1$s',
    mall_189: 'Vui lòng đặt địa chỉ giao hàng',
    mall_190: 'Quầy thanh toán',
    mall_191: '% Thanh toán 1$s',
    mall_192: 'Lệnh của tôi',
    mall_193: 'Tất cả',
    mall_194: 'Bạn chưa có đơn đặt hàng',
    mall_195: 'Tổng%1$s mục, tổng',
    mall_196: 'Hủy đơn hàng',
    mall_197: 'Thanh toán',
    mall_198: 'Lệnh xóa',
    mall_199: 'Xem hậu cần',
    mall_201: 'Đánh giá',
    mall_202: 'Chi tiết hoàn tiền',
    mall_203: 'Đang chờ thanh toán',
    mall_204: 'Đang chờ hoàn tiền',
    mall_205: 'Khác',
    mall_206: 'Mã đặt hàng:%1$s',
    mall_207: 'Tên người mua:%1$s',
    mall_208: 'Tổng%1$s mục',
    mall_209: 'Chuyển đến giao hàng',
    mall_211: 'Liên hệ người mua',
    mall_212: '%1$s, số tiền%2$s%3$s',
    mall_213: 'Hoàn tiền',
    mall_214: 'Đã gửi',
    mall_215: 'Đã nhận',
    mall_216: 'Đã hoàn thành',
    mall_217: 'Đã đóng',
    mall_218: 'Lệnh hoàn tiền',
    mall_219: 'Đơn hàng đã được vận chuyển',
    mall_220: 'Lệnh đã ký',
    mall_221: 'Lệnh đã hoàn thành',
    mall_222: 'Lệnh đã đóng',
    mall_223: 'Tất cả các lệnh',
    mall_225: 'Điền vào số lệnh logistics',
    mall_226: 'Chọn một công ty logistics',
    mall_227: 'Xác nhận giao hàng',
    mall_228: 'Địa chỉ bản sao',
    mall_230: 'Vận chuyển',
    mall_231: 'Yêu cầu thanh toán/thanh toán thực tế',
    mall_232: 'Mã đặt hàng:%1$s',
    mall_233: 'Chi tiết đơn hàng',
    mall_234: 'Mô tả khớp',
    mall_235: 'Phát hành',
    mall_236: 'Để lại một bình luận',
    mall_237: 'Em bé có phù hợp với mong đợi của bạn không? Hãy nói về ưu và nhược điểm của nó~',
    mall_237_1: 'đã sử dụng nó được một thời gian, bạn có nhiều kinh nghiệm hơn không? Chia sẻ với những người muốn mua',
    mall_238: 'Công khai',
    mall_239: 'Đánh giá lưu trữ',
    mall_240: 'Dịch vụ giao hàng',
    mall_241: 'Thái độ phục vụ',
    mall_242: 'Thêm ảnh',
    mall_243: 'Thêm video',
    mall_244: 'Vô danh',
    mall_245: 'Chú thích không được để trống',
    mall_246: 'Bình luận tiếp theo',
    mall_247: 'Thêm đánh giá',
    mall_248: 'Liên hệ dịch vụ khách hàng',
    mall_249: 'Quay số',
    '复制': 'Sao chép',
    mall_250: 'Thời gian đặt hàng:%1$s',
    mall_251: 'Phương thức thanh toán:%1USD',
    mall_252: 'Thời gian thanh toán:%1$s',
    mall_253: 'Xác nhận nhận',
    mall_254: 'Phương thức hoàn tiền',
    mall_255: 'Chỉ hoàn tiền',
    mall_256: 'Trả lại và hoàn tiền',
    mall_257: 'Lý do hoàn tiền',
    mall_258: 'Số tiền hoàn lại',
    mall_259: 'Vui lòng chọn',
    mall_260: 'Vui lòng điền vào mô tả vấn đề dài hơn 10 từ để chúng tôi có thể giúp đỡ tốt hơn',
    mall_261: 'Gửi ứng dụng',
    mall_262: 'Yêu cầu hoàn tiền',
    mall_263: 'Vui lòng chọn lý do hoàn tiền',
    mall_264: 'Hủy bỏ',
    mall_265: 'Áp dụng lại',
    mall_266: 'Nền tảng',
    mall_267: 'Lịch sử đàm phán',
    mall_268: 'Thông tin hoàn tiền',
    mall_269: 'Phương thức hoàn tiền:',
    mall_270: 'Số tiền hoàn lại:',
    mall_271: 'Lý do hoàn tiền:',
    mall_272: 'Thời gian ứng dụng:',
    mall_2721: 'Mã đặt hàng hoàn tiền:',
    mall_273: 'Mô tả vấn đề:',
    mall_274: 'Lý do từ chối:',
    mall_275: 'Lưu ý từ chối:',
    mall_276: 'Sau khi hủy yêu cầu hoàn tiền, đơn đặt hàng không thể yêu cầu hoàn lại tiền. Bạn có chắc chắn muốn hủy bỏ?" Chuỗi',
    mall_277: 'Đồng ý',
    mall_278: 'Trạng thái lệnh:',
    mall_279: 'Biệt danh của người mua:',
    mall_280: 'Hãy cố gắng liên lạc đầy đủ với người mua trước khi chọn từ chối',
    mall_281: 'Từ chối hoàn tiền',
    mall_282: 'Lý do từ chối',
    mall_283: 'Vui lòng điền vào mô tả chi tiết về lý do và bằng chứng để giúp giải quyết vấn đề của bạn nhanh chóng.',
    mall_284: 'Vui lòng chọn lý do từ chối',
    mall_285: 'Tiếp tục',
    mall_286: 'Nền tảng can thiệp',
    mall_287: 'Lý do kháng cáo',
    mall_288: 'Lý do kháng cáo',
    mall_289: 'Xem thêm bình luận',
    mall_290: 'với hình ảnh',
    mall_291: 'Thêm vào',
    mall_292: 'Tất cả bình luận',
    mall_293: 'Số dư tài khoản ($)',
    mall_294: 'Hồ sơ hoàn tiền',
    mall_295: 'Rút số dư',
    mall_296: 'Không có hồ sơ hoàn tiền liên quan',
    mall_297: 'Quản lý vé',
    mall_298: 'Thu nhập tích lũy',
    mall_299: 'Hồ sơ thanh toán',
    mall_300: 'Số dư lưu trữ',
    mall_301: 'Rút tiền',
    mall_302: 'trong giao dịch',
    mall_303: 'Không có hồ sơ thanh toán liên quan',
    mall_304: 'Có thể trích xuất',
    mall_305: 'Nhập số tiền rút',
    mall_306: 'Vui lòng nhập số tiền rút',
    mall_307: 'Tính năng này không được hỗ trợ trong bản xem trước',
    mall_308: 'Ứng dụng',
    mall_309: 'Tôi đã mua',
    mall_310: 'Mô tả ứng dụng',
    mall_311: 'Tôi đã đọc và đồng ý với',
    mall_312: 'Áp dụng ngay',
    mall_313: 'Tôi thấy',
    mall_314: 'Tôi đã tải lên',
    mall_315: 'Tải lên nội dung trả phí',
    mall_317: 'Tiêu đề nội dung',
    mall_318: 'Đề xuất các từ khóa, từ thuộc tính, từ tiếp thị, v.v., không quá 15 từ',
    mall_319: 'Trang bìa nội dung',
    mall_320: 'Ảnh bìa',
    mall_321: 'Giới thiệu nội dung',
    mall_322: 'Đề xuất giải thích các tính năng nội dung thông qua nội dung video, cốt truyện, thủ thuật học tập, v.v., không quá 100 từ',
    mall_323: 'Giới thiệu cá nhân',
    mall_324: 'Đề nghị điền vào danh tính tác giả, thành tích, tác phẩm trong quá khứ, v.v., không quá 50 từ',
    mall_325: 'Giá nội dung',
    mall_326: 'Tải lên video',
    mall_200: 'Xác nhận nhận',
    mall_229: 'Thông tin người mua',
    mall_316: 'Thể loại nội dung',
    mall_327: 'Video đơn',
    mall_328: 'Nhiều video',
    mall_329: 'Phụ đề',
    mall_330: 'Tối đa 15 ký tự',
    mall_331: 'Thêm video',
    mall_332: 'Xem trước',
    mall_333: 'Vui lòng chọn video',
    mall_334: 'Vui lòng đặt tiêu đề video',
    mall_335: 'Vui lòng chọn danh mục',
    mall_336: 'Vui lòng nhập tiêu đề nội dung',
    mall_337: 'Vui lòng nhập nội dung giới thiệu',
    mall_338: 'Vui lòng nhập thông tin cá nhân',
    mall_339: 'Vui lòng nhập giá',
    mall_340: 'Vui lòng chọn ảnh bìa',
    mall_341: '%1$s người đã mua',
    mall_342: 'Bị từ chối',
    mall_343: 'Chi tiết video',
    mall_345: 'Giới thiệu video',
    mall_346: 'Nhận xét của khán giả',
    mall_347: 'Chọn',
    mall_348: 'Thông tin tác giả',
    mall_349: 'Thời lượng:%1$s',
    mall_350: 'Phát lại',
    mall_351: '%1$s người đã đánh giá',
    mall_352: 'Đánh giá video',
    mall_353: 'Xem video',
    mall_354: 'Cửa hàng',
    mall_355: 'Trả tiền',
    mall_357: 'Bạn chưa mua nội dung trả phí',
    mall_358: 'Bạn chưa đăng nội dung trả phí',
    mall_356: 'Xem nội dung trả phí',
    mall_359: 'Mua nội dung trả phí',
    mall_360: 'Cực kỳ không hài lòng',
    mall_361: 'Không thỏa mãn',
    mall_362: 'Thỏa mãn',
    mall_363: 'Rất hài lòng',
    mall_364: 'Rất hài lòng',
    mall_365: 'Thông điệp đặt hàng',
    mall_366: ' Click để xem',
    mall_367: 'Thanh toán không thành công',
    mall_369: '(Người mua)',
    mall_370: 'Bạn có chắc chắn muốn xóa đơn đặt hàng không',
    mall_371: 'Bạn có chắc chắn muốn hủy đơn đặt hàng của mình không? Chuỗi',
    mall_372: 'Bạn có chắc mình đã nhận được sản phẩm không? Chuỗi',
    mall_373: 'Sản phẩm này đã bị loại bỏ',
    not_use: 'Xem thêm',
    mall_374: 'Sản phẩm',
    mall_375: 'Hàng hóa ngoài quầy',
    mall_376: 'off-sit',
    mall_377: 'Sản phẩm này là sản phẩm được liên kết off-site và có thể liên quan đến một số rủi ro nhất định. Hãy cẩn thận khi mua',
    mall_378: 'Tiếp tục mua',
    mall_379: 'Liên kết sản phẩm không tồn tại',
    '确认删除？': 'Bị từ chối',
    confirm_delete: 'Xác nhận xóa',
    mall_381: 'Bạn có chắc chắn muốn xóa sản phẩm này không? Chuỗi',
    mall_382: 'Vui lòng nhập tên nội dung trả phí',
    mall_383: 'Xác nhận sản phẩm bạn muốn lên kệ Chuỗi',
    mall_384: 'Xác nhận xóa sản phẩm',
    mall_385: 'Vui lòng thiết lập thông tin người nhận',
    mall_386: 'Vui lòng đồng ý với các điều khoản',
    minute: 'phút',
    '注销账号': 'Xóa tài khoản',
    '确认注销': 'Xác nhận Xóa tài khoản',
    '点击确认注销后，账号将立即注销': 'Tài khoản sẽ bị hủy ngay lập tức sau khi nhấp để xác nhận hủy.',
    '短信验证保障账户安全的同时简讯费用将由平台支付': '* Xác minh tin nhắn SMS đảm bảo an toàn cho tài khoản, phí SMS được thanh toán bởi nền tảng',
    '满足以下条件，才能注销当前账户': 'Tài khoản hiện tại chỉ có thể bị hủy nếu đáp ứng các điều kiện sau:',
    '注销条件': 'Điều kiện xóa tài khoản',
    '已通过': 'bởi',
    '未通过': 'Thất bại',
    mall_387: 'Vui lòng nhập tên sản phẩm',
    mall_388: 'Chưa có sản phẩm',
    mall_389: 'Sản phẩm mới',
    mall_390: 'Bán hàng',
    mall_391: 'Giá cả',
    mall_392: 'Đi mua',
    mall_393: 'Bộ sưu tập',
    mall_394: 'Bộ sưu tập của tôi',
    '搜索您感兴趣的话题': 'Chủ đề tìm kiếm',
    '话题': 'Chủ đề',
    '全部话题': 'Tất cả các chủ đề',
    '选择话题': 'Chọn chủ đề',
    '参与话题，让更多人看到': 'Chủ đề tham gia',
    '推荐话题': 'Đề xuất chủ đề',
    '搜索您感兴趣的话题': 'Chủ đề tìm kiếm',
    '热门话题': 'Chủ đề',
    '查看更多': 'Xem thêm',
    '每日任务': 'Nhiệm vụ hàng ngày',
    '未达成': 'Hoàn tác',
    '可领取': 'Nhận',
    '已领取': 'Đã nhận',
    '绘制礼物，进入涂鸦模式': 'Vẽ quà tặng và chuyển sang chế độ graffiti',
    '最少绘制10个礼物': 'Ít nhất 10 món quà được rút ra',
    '涂鸦': 'graffiti',
    '撤回': 'Rút tiền',
    '你撤回了一条消息': 'Bạn đã rút tin nhắn',
    '对方撤回了一条消息': 'Đối phương đã rút lại một tin nhắn',
    '该消息发送时间已超过两分钟，无法撤回': 'Tin nhắn này đã được gửi hơn hai phút và không thể rút lại',
    '撤回失败': 'Rút tiền không thành công',
    '保存到本地相册': 'Lưu vào album địa phương',
    '保存成功': 'Lưu thành công',
    '确定登出吗？': 'Bạn có chắc chắn muốn đăng xuất không',
    '退出': 'Đăng xuất',
    '登出': 'Đăng xuất',
    mall_395: 'Danh mục kinh doanh',
    mall_396: 'Quản trị viên đang được kiểm tra nhanh~',
    mall_397: 'Đánh giá không thành công, vui lòng gửi lại',
    mall_398: 'Gửi lại',
    mall_399: 'Thiết lập danh mục doanh nghiệp',
    mall_400: 'Vui lòng chọn cẩn thận, danh mục chính không thể thay đổi sau khi thiết lập thành công',
    mall_401: 'Chọn danh mục chính',
    mall_402: 'Chưa có mục yêu thích',
    mall_403: 'Sản phẩm đã được gỡ bỏ',
    mall_404: 'Sản phẩm của tôi',
    mall_405: 'Nền tảng',
    mall_406: 'Sản phẩm nền tảng',
    mall_407: 'Hiện tại không có sản phẩm nền tảng',
    mall_408: 'Phần thưởng',
    mall_409: 'Gửi hàng',
    mall_410: 'Hủy đại lý bán hàng',
    mall_411: 'Xác nhận hủy bán sản phẩm này Chuỗi',
    mall_412: 'Vui lòng liên hệ với chúng tôi tại Personal Center',
    mall_413: 'Cài đặt phần thưởng chia sẻ',
    mall_414: 'Hoa hồng ($)',
    '开启聊天室': 'Trò chuyện',
    '幸运奖池': 'Giải thưởng may mắn',
    '幸运抽奖': 'Rút thăm may mắn',
    '手机号归属地': 'Vị trí số điện thoại',
    '请输入地区名': 'Vui lòng nhập tên vùng',
    '以下信息均为必填项，为保证您的利益，请如实填写': 'yêu cầu thông tin sau. Để đảm bảo lợi ích của bạn, vui lòng điền vào',
    '真实姓名': 'Tên thật',
    '请填写您的真实姓名': 'Vui lòng điền tên thật của bạn',
    '手机号码': 'Di chuyển',
    '请填写您的手机号': 'Vui lòng điền số điện thoại của bạn',
    '身份证号': 'Số chứng minh thư',
    '请填写您的身份证号': 'Vui lòng điền số ID của bạn',
    '证件正面': 'Phía trước ID',
    '证件背面': 'Mặt sau của ID',
    '手持证件正面照': 'Mặt trước cầm tay',
    '（手持证件照示例）': '(có ảnh chứng minh thư)',
    '请按图示手持证件拍照\n确保证件信息清晰可见': 'Vui lòng giữ thẻ ID của bạn và chụp ảnh như thể hiện n Đảm bảo thông tin thẻ ID được hiển thị rõ ràng',
    '提交认证': 'Gửi chứng chỉ',
    '申请认证': 'Yêu cầu chứng nhận',
    '开通店铺保证金不足\n请先进行充值': 'Số dư không đủ, vui lòng nạp tiền trước',
    '立刻充值': 'Sạc ngay lập tức',
    '家族申请': 'Dòng ứng dụng',
    '家族名称': 'Họ',
    '请输入您要创建的家族名称': 'Vui lòng nhập họ',
    '个人姓名': 'Tên cá nhân',
    '请输入您的姓名': 'Vui lòng nhập tên của bạn',
    '抽成比例': 'Tỷ lệ cào',
    '请填写0-100之间的整数': 'Vui lòng điền số nguyên từ 0 đến 100',
    '家族简介': 'Lưu trữ gia đình',
    '请简单介绍一下您的家族': 'Giới thiệu gia đình bạn',
    '证件照片': 'Ảnh thẻ căn cước',
    '家族图片': 'Ảnh gia đình',
    '手持证件背面照': 'Xem phía sau cầm tay',
    '请输入抽成比例': 'Vui lòng nhập tỷ lệ hoa hồng',
    '请输入家族简介': 'Vui lòng nhập tệp gia đình',
    '提交申请': 'Gửi ứng dụng',
    '等待上麦': 'Đang chờ',
    '聊天室': 'Trò chuyện',
    '上麦互动': 'Tương tác',
    '申请上麦': 'Ứng dụng',
    '取消排队': 'Hủy hàng đợi',
    '您当前的顺比特': 'Lệnh hiện tại của bạn',
    '当前上麦申请': 'Ứng dụng hiện tại',
    '上麦': 'Đồng ý',
    '上麦成功': 'Lúa mì thành công',
    '上麦被拒绝': 'Lúa mì bị từ chối',
    '控麦': 'lúa mì đối chứng',
    '下麦': 'Thoát',
    '闭麦': 'Tắt micro',
    '禁麦': 'Cấm',
    '取消禁麦': 'Hủy bỏ',
    '开麦': 'Mở micro',
    '您已被下麦': 'Bạn đã bị xóa',
    '已设定为静音': 'được thiết lập để im lặng',
    '已取消静音': 'Không tính',
    '退出直播间': 'Thoát khỏi phòng phát sóng',
    '聊天室最小化': 'Giảm thiểu phòng chat',
    '主持': 'Máy chủ',
    '全麦': 'Tất cả',
    '一麦': 'No.1',
    '二麦': 'No.2',
    '三麦': 'No.3',
    '四麦': 'No.4',
    '五麦': 'No.5',
    '六麦': 'No.6',
    '七麦': 'No.7',
    '八麦': 'No.8',
    '我已阅读并同意':'Tôi đã đọc và đồng ý ',
    '登录即表示您同意':'Login means you agree to the',
    '《隐私政策》':'《Chính sách bảo mật》',
    '及':'và ',
    '和':'và ',
    '《服务协议》':'《Thỏa thuận dịch vụ》',
    '服务协议和隐私政策':'Thỏa thuận dịch vụ và chính sách bảo mật',
    '感谢您的支持，为了更好地维护您的权益，请阅读并同意':'Cảm ơn sự hỗ trợ của bạn, để bảo vệ tốt hơn quyền và lợi ích của bạn, vui lòng đọc và đồng ý',
    '立即登录':'Đăng nhập ngay',
    '请选择':'chọn',
    a_059: 'Hành động này không thể được sử dụng khi phòng phát sóng trực tiếp mở.',
    a_060: 'Phát trực tiếp bằng giọng nói',
    a_061: 'Vui lòng điền thông tin chứng chỉ',
    a_062: 'Vui lòng chọn người gửi',
    beauty: 'Đẹp',
    pay_succ: 'Thanh toán thành công',
    pay_fail: 'Thanh toán không thành công',
    pay_cancel: 'Hủy thanh toán',
    a_063: 'Trung tâm cá nhân',
    a_064: 'Tạo gia đình',
    a_065: 'Thông tin cơ bản',
    a_066: 'Tải lên thông tin nhận dạng',
    a_067: 'Nhấp để nhảy đến ứng dụng của bên thứ ba',
    a_068: 'Bạn chưa đăng nhập',
    a_069: 'Đăng nhập vào tài khoản của bạn để xem nội dung tuyệt vời',
    a_070: 'Chia sẻ',
    a_071: 'Chia sẻ kiếm được hoa hồng%1$s',
    a_072: 'Chia sẻ tin tức',
    a_073: 'Chia sẻ với bạn bè',
    a_074: 'Chia sẻ',
    a_075: 'Hoa hồng chia sẻ',
    a_076: 'Sạc ngay lập tức',
    a_077: 'Quà tặng sang trọng đầu tiên của Hoa Thuận',
    a_078: 'Quảng cáo',
    a_079: 'Vui lòng chọn phương thức thanh toán',
    a_080: 'Tác giả',
    a_081: 'Người theo dõi đã mua%1$s $',
    a_082: 'Sản phẩm chia sẻ',
    a_083: 'Bạn có thực sự muốn xóa bộ sưu tập đã chọn không? Chuỗi',
    login_tip_001: 'Thỏa thuận dịch vụ và chính sách bảo mật',
    login_tip_002: 'Vui lòng đọc kỹ và hiểu đầy đủ các điều khoản của Thỏa thuận dịch vụ và Chính sách bảo mật, bao gồm nhưng không giới hạn, để cung cấp cho bạn tin nhắn tức thời, chia sẻ nội dung và các dịch vụ khác, chúng tôi cần thu thập thông tin thiết bị và thông tin cá nhân của bạn. Bạn có thể xem và quản lý ủy quyền của mình trong Cài đặt. Để biết thêm thông tin, bạn có thể đọc"Chính sách bảo mật" và"Thỏa thuận dịch vụ". Nếu bạn đồng ý, vui lòng nhấp vào Đồng ý chấp nhận dịch vụ của chúng tôi',
    login_tip_003: 'Đăng nhập cho biết bạn đồng ý với"Chính sách bảo mật" và"Thỏa thuận dịch vụ"',
    login_tip_004: '"Chính sách bảo mật"',
    login_tip_005: '"Thỏa thuận dịch vụ"',
    a_084: '\@Bạn bè',
    video_at_tip_1: 'Bạn đã @ anh ấy',
    video_at_tip_2: 'đã @ những người có cùng biệt danh',
    a_085: 'Người hâm mộ',
    a_086: 'Đã thích',
    a_087: '\@Me',
    a_088: 'Bình luận',
    a_089: '%1$s thích tác phẩm của bạn',
    a_090: 'Phần thưởng bổ sung',
    a_091: 'Chi tiết thời gian thực',
    a_092: 'Xem chi tiết',
    a_093: 'Trò chuyện chưa mở~',
    a_094: '%1$s bình luận về công việc của bạn',
    a_095: '%1$s trong 2$s @ bạn',
    a_096: '%1$s theo dõi bạn',
    a_097: '%1$s thích bình luận của bạn',
    a_098: '%1$s đã trả lời bình luận của bạn',
    a_099: 'Xóa',
    a_100: 'Cơ hội nạp tiền đầu tiên đã hết',
    a_101: 'Bạn có chắc chắn muốn xóa tất cả các kỷ lục chiến thắng',
    a_102: 'Vui lòng chọn thời gian cấm',
    a_103: 'Tôi thấy',
    a_104: 'Tiêu điểm của tôi',
    a_105: '%1$s người đang phát sóng trực tiếp',
    a_106: 'Tất cả danh mục',
    a_107: 'Mua tại chỗ',
    a_108: 'Bán hàng trực tiếp chính thức · Đảm bảo chính hãng',
    a_109: 'Chứng nhận',
    a_110: 'Yêu cầu chứng nhận',
    a_111: 'Dịch vụ của tôi',
    a_112: 'Thêm dịch vụ',
    a_113: 'Gia hạn tư cách thành viên',
    a_114: 'Hết hạn:%1$s',
    a_115: 'Quà tặng dành riêng cho thành viên',
    a_116: 'Đã thích',
    a_117: 'Thay thế',
    a_118: 'Chuyển đến Đóng',
    a_119: 'Thay đổi mật khẩu',
    a_120: 'Chế độ thiếu niên',
    a_121: 'Chế độ thanh thiếu niên không được kích hoạt',
    a_121_2: 'Chế độ thanh niên mở',
    a_122: 'Để kích hoạt chế độ thanh thiếu niên, trước tiên bạn cần thiết lập một mật khẩu riêng biệt.',
    a_123: 'Không thể thực hiện các thao tác như nạp tiền, thưởng,',
    a_124: 'Tự động mở khóa thời gian sử dụng không quá 40 phút mỗi ngày và không thể sử dụng từ 22:00 đến 6:00 ngày hôm sau',
    a_125: 'Mở chế độ thiếu niên',
    a_126: 'Tắt chế độ thiếu niên',
    a_127: 'Đặt mật khẩu',
    a_128: 'Đặt mật khẩu mới',
    a_129: 'Nhập mật khẩu',
    a_130: 'Mật khẩu được thiết lập thành công, hãy nhớ mật khẩu bạn đã thiết lập',
    a_131: 'Vui lòng nhập mật khẩu hiện tại',
    a_132: 'Vui lòng nhập mật khẩu mới',
    a_133: 'Vui lòng xác nhận mật khẩu mới',
    a_135: 'Chuyển sang chế độ thiếu niên',
    a_136: 'Không có thông tin sản phẩm trong chế độ thanh thiếu niên',
    a_137: 'Chế độ thanh thiếu niên không hỗ trợ tính năng này',
    a_138: 'Không dành cho trẻ vị thành niên',
    a_139: 'Đặt lại',
    a_140: 'Xác nhận sửa đổi',
    map_gaode: 'Bản đồ Gaude',
    map_baidu: 'Bản đồ Baidu',
    map_tencent: 'Bản đồ Tencent',
    a_141: 'Bạn chưa cài đặt phần mềm điều hướng',
    open_notification: 'Vui lòng mở thông báo',
    '选择直播内容': 'Chọn nội dung thời gian thực',
    '购物车': 'Giỏ hàng',
    '在线观众': 'Trình xem trực tuyến',
    '开启定位服务': 'Mở dịch vụ định vị',
    '开启定位提示': 'Để giới thiệu cho bạn các chương trình phát sóng trực tiếp và video gần đó, giúp bạn hiển thị vị trí của mình khi sử dụng các dịch vụ có liên quan, bật vị trí và khám phá cuộc sống xung quanh bạn',
    '设置': 'Cài đặt',
    '人在看': 'Bộ xem',
    '表情': 'Biểu tượng cảm xúc',
    '进入全功能模式': 'Chuyển sang chế độ đầy đủ chức năng',
    '不同意': 'Không đồng ý',
    '同意': 'Đồng ý',
    '不同意并进入基本功能模式': 'Không đồng ý và vào chế độ chức năng cơ bản',
    '什么是基本功能模式': 'Chế độ chức năng cơ bản là gì',
    '星球探宝': 'Truy tìm kho báu',
    '幸运大转盘': 'Chúc may mắn',
    '冥王星': 'Sao Diêm Vương',
    '天王星': 'Thiên Vương tinh',
    '海王星': 'Hải Vương tinh',
    '规则说明': 'Mô tả quy tắc',
    '中奖记录': 'Kỷ lục chiến thắng',
    '暂无抽奖记录': 'Không có kỷ lục chiến thắng',
    '您还没有中奖记录': 'Bạn chưa có kỷ lục chiến thắng',
    '暂无抽奖排行榜': 'Chưa có bảng xếp hạng xổ số',
    '恭喜获得': 'Chúc mừng bạn',
    '未获得': 'Không có',
    '未获得提示': 'Rất tiếc bạn đã bỏ lỡ giải thưởng lớn',
    '获得礼物提示': 'Quà tặng được lưu trữ trong ba lô',
    '排名': 'Xếp hạng',
    '用户信息': 'Người dùng',
    '奖品累计价值': 'Tổng giá trị',
    'x个': '%1$s',
    '玩法介绍': 'Luật chơi',
    '参与记录': 'Lịch sử trò chơi',
    '跳过动画': 'Bỏ qua hoạt hình',
    '单击': 'Nhấp vào',
    '十连击': '10 kết hợp amps',
    '百连击': '100 lần nhấp để kết hợp',
    '手游直播': 'Trò chơi trực tiếp',
    '开始录屏': 'Bắt đầu ghi màn hình',
    '用户连麦': 'Liên kết micro',
    private_msg_close_tip: 'Tin nhắn riêng đã bị đóng và tạm thời không khả dụng',
    'token已过期': 'Trạng thái đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại',
    live_type: 'Thể loại trực tiếp:',
    live_room_voice: 'Phòng chat bằng giọng nói',
    live_room_video: 'Phòng trò chuyện video',

    first_charge_title: 'Gói quà đầu tiên',
    first_charge_title_1: '1 $ gói quà',
    first_charge_title_2: '10 $ gói quà',
    first_charge_title_3: '100 $ gói quà',
    first_charge_desc: 'Món quà lớn đầu tiên, hãy đến và nhận phần thưởng',
    first_charge_desc_1: 'Tặng gạch sau khi nạp tiền',
    first_charge_desc_2: 'Tặng gạch và quà tặng sau khi nạp tiền',
    first_charge_desc_3: 'Tặng gạch và VIP sau khi nạp tiền',



    beauty_mh_001: 'Hình dán',
    beauty_mh_002: 'Đẹp',
    beauty_mh_003: 'Hiệu ứng đặc biệt',
    beauty_mh_004: 'Gương móc',
    beauty_mh_005: 'Nhãn dán cơ bản',
    beauty_mh_006: 'Nhãn dán cao cấp',
    beauty_mh_007: 'Mặt nạ cơ bản',
    beauty_mh_008: 'Mặt nạ cao cấp',
    beauty_mh_009: 'Tải về nhãn dán không thành công',
    beauty_mh_010: 'đẹp',
    beauty_mh_011: 'đẹp',
    beauty_mh_012: 'Làm đẹp bằng một cú nhấp chuột',
    beauty_mh_013: 'Bộ lọc',
    beauty_mh_014: 'Hình mờ',
    beauty_mh_015: 'Hành động',
    beauty_mh_016: 'Đẹp',
    beauty_mh_no: 'Ảnh gốc',
    beauty_mh_meibai: 'Làm trắng da',
    beauty_mh_mopi: 'Dermabrasion',
    beauty_mh_hongrun: 'Thô bạo',
    beauty_mh_liangdu: 'Độ sáng',
    beauty_mh_dayan: 'Đôi mắt to',
    beauty_mh_meimao: 'Lông mày',
    beauty_mh_yanju: 'Khoảng cách mắt',
    beauty_mh_yanjiao: 'Góc nhìn',
    beauty_mh_shoulian: 'Khuôn mặt gầy',
    beauty_mh_zuixing: 'Kiểu miệng',
    beauty_mh_shoubi: 'shoubi',
    beauty_mh_xiaba: 'Hàm',
    beauty_mh_etou: 'trán',
    beauty_mh_changbi: 'Mũi dài',
    beauty_mh_xuelian: 'Tạo hình khuôn mặt',
    beauty_mh_kaiyanjiao: 'Mở mắt ra',
    beauty_mh_biaozhun: 'Tiêu chuẩn',
    beauty_mh_youya: 'thanh lịch',
    beauty_mh_jingzhi: 'Tinh tế',
    beauty_mh_keai: 'Dễ thương',
    beauty_mh_ziran: 'Thiên nhiên',
    beauty_mh_wanghong: 'Người nổi tiếng trên mạng',
    beauty_mh_tuosu: 'Sửa đổi',
    beauty_mh_gaoya: 'Thanh lịch',
    beauty_mh_filter_no: 'Không có',
    beauty_mh_filter_langman: 'Lãng mạn',
    beauty_mh_filter_qingxin: 'Tươi',
    beauty_mh_filter_weimei: 'Thẩm mỹ',
    beauty_mh_filter_fennen: 'Màu hồng',
    beauty_mh_filter_huaijiu: 'Hoài cổ',
    beauty_mh_filter_qingliang: 'Tuyệt vời',
    beauty_mh_filter_landiao: 'Nhạc blues',
    beauty_mh_filter_rixi: 'Tiếng Nhật',
    beauty_mh_filter_chengshi: 'Thành phố',
    beauty_mh_filter_chulian: 'Mối tình đầu',
    beauty_mh_filter_chuxin: 'Mục đích ban đầu',
    beauty_mh_filter_danse: 'Đơn sắc',
    beauty_mh_filter_fanchase: 'Đối chiếu màu',
    beauty_mh_filter_hupo: 'màu hổ phách',
    beauty_mh_filter_meiwei: 'Tuyệt vời',
    beauty_mh_filter_mitaofen: 'Bột đào',
    beauty_mh_filter_naicha: 'Trà sữa',
    beauty_mh_filter_pailide: 'Hình ảnh',
    beauty_mh_filter_wutuobang: 'Không tưởng',
    beauty_mh_filter_xiyou: 'Quả bưởi',
    beauty_mh_filter_riza: 'Hàng ngày linh tinh',
    beauty_mh_pro_filter_heimao: 'Mèo đen',
    beauty_mh_pro_filter_heibai: 'đen và trắng',
    beauty_mh_pro_filter_bulukelin: 'Hoa Kỳ',
    beauty_mh_pro_filter_pingjing: 'Calm',
    beauty_mh_pro_filter_lengku: 'Lạnh',
    beauty_mh_pro_filter_kaiwen: 'Kevin',
    beauty_mh_pro_filter_lianai: 'tình yêu',
    beauty_mh_pro_filter_jianbao: 'Bản demo',
    beauty_mh_texiao_linghun: 'Kinh nghiệm ngoài cơ thể',
    beauty_mh_texiao_doudong: 'Rung động',
    beauty_mh_texiao_shanbai: 'Đèn flash trắng',
    beauty_mh_texiao_maoci: 'Glitch',
    beauty_mh_texiao_huanjue: 'ảo giác',
    beauty_mh_texiao_msk: 'Mô phỏng 1',
    beauty_mh_texiao_msk_yuan: 'Mô phỏng 2',
    beauty_mh_texiao_msk_san: 'Mô phỏng 3',
    beauty_mh_texiao_msk_liu: 'Mô phỏng 4',
    beauty_mh_texiao_action_no: 'Ảnh gốc',
    beauty_mh_texiao_action_head: 'Tìm kiếm',
    beauty_mh_texiao_action_mouth: 'Mở miệng',
    beauty_mh_texiao_action_eye: 'Nhấp nháy',
    beauty_mh_haha_waixingren: 'Người ngoài hành tinh',
    beauty_mh_haha_li: 'Mặt lê',
    beauty_mh_haha_shou: 'Khuôn mặt gầy',
    beauty_mh_haha_jingxiang: 'Mặt gương',
    beauty_mh_haha_pianduan: 'Khuôn mặt phân mảnh',
    beauty_mh_haha_daoying: 'Phản chiếu nước',
    beauty_mh_haha_xuanzhuan: 'Gương xoắn ốc',
    beauty_mh_haha_yuyan: 'Máy ảnh mắt cá',
    beauty_mh_haha_zuoyou: 'Trái và phải',
    beauty_mh_makeup_none: 'Ảnh gốc',
    beauty_mh_makeup_jiemao: 'Lông mi',
    beauty_mh_makeup_chuncai: 'Bóng môi',
    beauty_mh_makeup_saihong: 'đỏ mặt',
    beauty_mh_makeup_yanying: 'Phấn mắt',
    beauty_mh_makeup_yanxian: 'Bút kẻ mắt',
    beauty_none: '',
    beauty_mh_texiao_action_head_tip: 'Vui lòng tìm',
    beauty_mh_texiao_action_mouth_tip: 'Mở miệng ra',
    beauty_mh_texiao_action_eye_tip: 'Nhấp nháy mắt',
    beauty_mh_texiao_action_downloading: 'Đang tải về nhãn dán, vui lòng đợi',

    game_zjh: 'Ba thẻ poker',
    game_zp: 'Bánh xe của sự giàu có',
    game_choose_game: 'Chọn trò chơi',
    game_wait_start: 'Sắp bắt đầu, vui lòng chờ',
    game_charge: 'nạp tiền',
    game_win: 'Thắng',
    game_wait_end: 'Vui lòng đợi cho đến khi trò chơi kết thúc',
    game_wan: 'W',
    game_zjh_role_name_1: 'Cầu x2',
    game_zjh_role_name_2: 'Điêu Thuyền 2',
    game_zjh_role_name_3: 'Tiểu Kiều x2',
    game_start_support: 'Bắt đầu hỗ trợ',
    game_show_result: 'Hiển thị kết quả',
    game_lose_tip: 'Thắng hay thua là vấn đề tiêu chuẩn',

    '我点亮了': 'Tôi thắp sáng',
    '被踢出房间': ' Bị đuổi ra khỏi phòng',
    '被永久禁言': ' Bị cấm vĩnh viễn',
    '被本场禁言': ' Đã bị cấm truy cập trang web này',
    '被设为管理员': ' Đặt làm quản trị viên',
    '被取消管理员': ' Đã xóa Admin',
    '在直播间发红包啦快去抢哦': 'Phát lì xì trong phòng truyền hình trực tiếp! Bắt lấy nó~',
    '欢迎来到直播间': 'Chào mừng bạn đến với phòng phát sóng trực tiếp, chúng tôi ủng hộ việc phát sóng trực tiếp xanh. Các tài khoản có nội dung và ảnh bìa trực tiếp bất hợp pháp, nội dung khiêu dâm, hút thuốc và uống rượu, lừa đảo, tụ tập đông người để gây rắc rối, v.v. sẽ bị cấm và cảnh sát Internet sẽ tuần tra trực tuyến 24 giờ mỗi ngày!',

    '主播离开一下': 'Mỏ neo rời đi một lúc, niềm vui sẽ không bị gián đoạn, đừng rời xa',
    '主播回来了': 'Chủ nhà đã trở lại!',
    '关注了主播': ' đi theo mỏ neo',



    '收藏': 'sưu tầm',
    '热门': 'Phổ biến',
    '社区': 'Cộng đồng',
    '游戏': 'Trò chơi',
    '视频': 'Băng hình',
    '终极会员': 'Thành viên cuối cùng',
    '启用': 'kích hoạt',
    '充值': 'Nạp điện',
    '奖励': 'phần thưởng',
    '收入': 'Thu nhập',
    '提现': 'Rút tiền mặt',
    '钱包':'Ví tiền',
    '我的钱包': 'ví của tôi',
    '每日任务':'Nhiệm vụ hàng ngày',
    '因汇率波动充值金额可能会存在变化 请以支付时金额为准':'Số tiền nạp có thể thay đổi do biến động tỷ giá hối đoái, tùy thuộc vào số tiền tại thời điểm thanh toán',
    '看视频20秒得现金打款': 'Xem video trong 20 giây và nhận tiền mặt',
    '欢迎登录': 'Rất vui được gặp bạn !',
    '个人中心': 'Trung tâm cá nhân',

    '消息': 'Tin nhắn',


    '我的现金': 'Tiền của tôi',

    '红包已经达到今日上限': 'Phong bì màu đỏ đã đạt đến giới hạn ngày hôm nay',

    '总数': 'Tổng cộng',
    '首充礼包': 'Gói quà tặng nạp tiền lần đầu',
    '快来充值领奖励吧': 'Hãy đến và nạp tiền để nhận phần thưởng',
    '请联系管理员充值': 'Vui lòng liên hệ với quản trị viên để nạp tiền',
    '现金': 'tiền mặt:',
    '功能暂未上线': 'Chức năng này chưa trực tuyến, vui lòng theo dõi',

    '电话区号': '+84',

    '倍速': 'Tỷ lệ',
    '两倍快进中': '2x nhanh về phía trước',

    '切换倍速提示': 'Đã chuyển sang phát lại tốc độ {} lần',

    '验证码': 'Mã xác nhận',
    '同意并登录': 'Đồng ý và đăng nhập',
    '直播中': 'Live streaming',
    '确定取消关注': 'Xác nhận hủy theo dõi %1$s ?',
    '取消关注': 'Hủy theo dõi',
    '继续关注': 'Tiếp tục theo dõi',

    // 首次启动引导
    '领取': 'nhận được',
    '欢迎来到LuckyLive': 'Chào mừng đến với Lucky Live',
    '你可以在这里发表视频': 'Bạn có thể đăng video ở đây',
    '看视频可以获得金币': 'Phần thưởng tiền vàng khi xem video',
    '您可以在这里开始直播': 'Bạn có thể bắt đầu phát sóng trực tiếp tại đây',
    '您可以在这里提取现金': 'Bạn có thể rút tiền mặt tại đây',
    '知道了': 'Tôi hiểu rồi',

    // 新直播UI
    '普通': 'Bình thường',
    '热门标签': 'Phổ biến',
    '幸运标签': 'MAY MẮN',
    '守护标签': 'Bảo vệ',
    '钻石余额不足充值后才可继续赠送礼物': 'Số dư kim cương không đủ Bạn có thể tiếp tục gửi quà sau khi nạp tiền.',
    '去充值': 'Đi để nạp tiền',

    '新消息': 'tin nhắn mới',
    '刚刚': 'lúc nãy',
    '分钟前': 'phút trước',
    '昨天': 'Hôm qua',

    '周一': 'Thứ hai',
    '周二': 'Thứ ba',
    '周三': 'Thứ Tư',
    '周四': 'Thứ năm',
    '周五': 'Thứ sáu',
    '周六': 'Thứ bảy',
    '周日': 'Chủ nhật',

    '上周一': 'Thứ Hai tuần trước',
    '上周二': 'Thứ ba tuần trước',
    '上周三': 'Thứ tư vừa rồi',
    '上周四': 'Thứ Năm tuần trước',
    '上周五': 'Thứ sáu trước',
    '上周六': 'Thứ bảy tuần trước',
    '上周日': 'Chủ Nhật trước',

    '钻石': 'kim cương',

    '支付': 'Sự chi trả',
    '支付方式': 'Phương thức thanh toán',
    '选择支付方式': 'Chọn phương thức thanh toán',
    '订单号': 'Số đơn hàng:',
    '已支付': 'Đã thanh toán',
    '订单请求中': 'Đang yêu cầu đặt hàng, vui lòng đợi...',
    '订单确认中': 'Xác nhận đơn hàng, vui lòng đợi...',
    '返回': 'Cái lưng',
    '支付成功': 'Thanh toán thành công',
    '支付失败': 'Thanh toán không thành công',
    '排行榜': 'Danh sách xếp hạng',
    '收益榜': 'BXH Lợi Ích',
    '贡献榜': 'BXH Của Cải',

    '下载APP': 'Tải Lucky Live App',
    '请下载Lucky Live APP':'Tải ứng dụng Lucky Live',
    '条':'',
    '私信给': 'Gửi thư riêng.',
    '发送': 'Gửi',

    '钻石记录':'Hồ sơ kim cương',
    '金币记录':'Kỷ lục vàng',
    '赠送礼物':'Quà tặng',
    '收到礼物':'Nhận quà',
    '游戏结算':'Giải quyết trò chơi',
    '后台充值':'Nạp tiền nền',
    '提款费':'Phí rút tiền',
    '手续费':'Phí thủ tục',
    '暂无数据':'Không có dữ liệu.',
    '提现钻石数':'Số kim cương rút ra',
    '可转化为':'Chuyển đổi thành',
    '提款':'Rút tiền',
    '单次提现范围':'Phạm vi rút mỗi lần ',
    '钻石每天可提现':'Kim cương mỗi ngày',
    '次':'Thứ hai',
    '提款规则':'Quy định rút tiền',
    '进行中':'Đang tiến hành',
    '成功':'Thành công',
    '账户':'Tài khoản',
    '查看原因':'Xem lý do',
    '提款方式':'Phương thức rút tiền',
    '选择到账银行卡': 'Chọn thẻ ngân hàng',
    '请注意各银行到账时间': 'Vui lòng chú ý đến thời gian thanh toán của từng ngân hàng.',
    '使用新卡提现': 'Rút tiền với thẻ mới',
    '编辑资料':'Sửa tài liệu',
    '男':'Nam',
    '女':'Phụ nữ',
    '点击设置':'Click vào settings',
    '修改昵称':'Thay đổi nickname',
    '保存':'Lưu',
    '修改签名':'Sửa chữ ký',
    '记得填写名字哦':'Nhớ điền tên nhé.',
    '这家伙很懒，什么都没留下':'Anh chàng này lười biếng và không để lại gì',
    '是否保存修改':'Lưu sửa đổi',
    '名字不能为空':'Tên không được để trống',
    '是否退出登录':'Thoát đăng nhập',
    '退出登录':'Đăng nhập',
    '很高兴认识你':'Rất vui được gặp bạn!',
    '英文':'English',
    '越南语':'Việt Nam',
    '繁体中文':'Trung Quốc truyền thống',

    '总金额':'Tổng số tiền',
    '全部':'Tất cả',
    '可提现钻石':'Kim cương có thể rút ra',
    '原生APP': 'App gốc',
    '桌面快捷APP': 'APP nhanh trên desktop',

    '您还未登录':'Bạn chưa đăng nhập',
    '登入账号查看精彩内容':'Đăng nhập tài khoản để xem nội dung hay',

    '公会管理': 'Quản lý Guild',
    '公会编号': 'Số guild',
    '公会主播': 'Phát thanh viên guild',
    '详细': 'Chi tiết',
    '旗下主播': 'Phát thanh viên.',
    '旗下主播收益': 'Thu nhập của người dẫn chương trình.',
    '当月活跃主播': 'Phát thanh viên hoạt động trong tháng',
    '公会分成收益': 'Guild chia lợi nhuận',
    '本月分成比例': 'Tỷ lệ chia tháng này',
    '本月总收益': 'Tổng thu nhập tháng này',
    '公会主播收益': 'Guild Anchor thu nhập',
    '当月收益': 'Thu nhập tháng',
    '昨日收益': 'Thu nhập hôm qua',
    '平台通知': 'Thông báo nền tảng',
    '违规记录': 'Hồ sơ vi phạm',
    '分成比例': 'Chia tỷ lệ',
    '钻石分成比例': 'Kim cương chia tỷ lệ',
    '金币分成比例': 'Tỷ lệ chia tiền vàng',
    '钻石礼物分成比例': 'Quà tặng kim cương chia tỷ lệ',
    '金币礼物分成比例': 'Tiền vàng quà tặng chia tỷ lệ',
    '请输入分成比例': 'Vui lòng nhập tỷ lệ chia',
    '总收益': 'Tổng lợi nhuận',
    '场次': 'Diễn đàn',
    '钻石礼物': 'Quà tặng kim cương',
    '金币礼物': 'Quà tặng vàng',
    '其他': 'Khác',
    '主播分成': 'Phát thanh viên chia',
    '结算金额': 'Số tiền thanh toán',
    '累计收益': 'Thu nhập tích lũy',
    '钻石收益': 'Thu nhập kim cương',
    '直播时长': 'Thời lượng livestream',
    '有效直播天数': 'Số ngày phát sóng trực tiếp hiệu quả',
    '导出': 'Xuất',
    '筛选': 'Lọc',
    '选择筛选方式': 'Chọn cách lọc',
    '主播': 'Trang chủ',
    '昵称或id号': 'Biệt danh hoặc ID',
    '主播等级': 'Xếp hạng neo',
    '时间范围': 'Khoảng thời gian',
    '取消': 'Hủy bỏ',
    '确定': 'Xác định',
    '导出成功': 'Xuất thành công',
    '在浏览器中打开链接，即可下载': 'Mở liên kết trong trình duyệt và bạn có thể tải xuống',
    '复制链接': 'Sao chép liên kết',
    '主播详情': 'Chi tiết neo',
    '即：用户赠送价值100钻石的礼物，主播可获得100*50%=50钻石': 'Ví dụ: Người dùng tặng quà trị giá 100 kim cương, người dẫn chương trình sẽ nhận được 100 * 50%=50 kim cương.',
    '即：用户赠送价值1000金币的礼物，主播可获得1000*50%=500金币，关播后统一兑换为钻石': 'Tức là: Người dùng tặng quà trị giá 1000 kim tệ, người dẫn chương trình có thể nhận được 1000 * 50%=500 kim tệ, sau khi tắt chương trình sẽ thống nhất đổi thành kim cương.',
    '个人信息': 'Thông tin cá nhân',
    '姓名': 'Tên',
    '国家/地区': 'Quốc gia',
    '城市': 'Thành phố',
    '手机号': 'Số điện thoại',
    '直播信息': 'Thông tin trực tiếp',
    '累计直播时长': 'Tổng thời gian phát sóng trực tiếp',
    '签约信息': 'Thông tin đăng ký',
    '签约时间': 'Thời gian đăng ký',
    '开播时间': 'Thời gian phát sóng',
    '查看直播记录': 'Xem bản ghi trực tiếp',
    '点击查看': 'Click để xem',
    '警告': 'Cảnh báo',
    '封禁7天': 'cấm 7 ngày',
    '登录用户不一致，请重新登录':'Đăng nhập người dùng không nhất quán, vui lòng đăng nhập lại',

    '任务中心': 'Trung tâm nhiệm vụ',
    '新增一个视频': 'Thêm video mới',
    '观看视频': 'Xem video',
    '轻松赚金币，快速提现': 'Kiếm tiền dễ dàng, rút tiền nhanh',
    '开播10分钟': 'Mở livestream 10 phút',
    '观看直播10分钟': 'Xem livestream 10 phút',
    '关注主播': 'Theo dõi chủ phòng',
    '送1份礼物': 'Tặng 1 món quà',
    '发布1条动态': 'Phát hành 1 điều động',
    '每日0点重置，所有时间均以UTC+07:00为准':'Sẽ làm mới vào 0:00 giờ mỗi ngày, tất cả mọi nơi đều lấy UTC+07:00 làm chuẩn',
    '本活动与苹果公司无关':'Sự kiện này không liên quan đến Apple.',
    '观看视频，快速提现':'Xem video, rút tiền nhanh',

    '直播收益': 'Doanh thu livestream',
    '公会管理': 'Quản lý Guild',
    '今日开播时长（仅统计大于10min的场次）': 'Thời lượng livestream hôm nay (Chỉ tính những buổi trên 10 phút)',
    '场统计': 'Buổi',
    '日统计': 'Ngày',
    '月统计': 'Tháng',
    '日期': 'Ngày',
    '时长': 'Thời lượng',
    '开播时间':'Thời gian bắt đầu',
    '关播时间':'Thời gian kết thúc',
    '今日收益': 'Lợi nhuận hôm nay',
    '本月收益': 'Thu nhập tháng này',
    '本月开播时长': 'Thời lượng livestram tháng này',
    '在线天数': 'Số ngày online',
    '收益': 'Lợi nhuận',
    '上拉加载更多...': 'Kéo lên và tải thêm...',
    '公会管理后台': 'Guild management backend',
    // '今日已看满10分钟，明天再来':'Hôm nay đã xem hết 10 phút, ngày mai lại đến.',
    '今日已看满':'Hôm nay đã xem hết ',
    '分钟，':'phút, ',
    '明天再来':'ngày mai lại đến.',
    '点击加载更多':'Click để tải thêm',

    '最多可添加':'Tối đa có thể thêm ',
    '个提现账号':' Tài khoản rút tiền.',

    '场次：开播时间~关播时间':'Disable (adj): khuyết tật (',
    '钻石礼物：本场直播收到钻石礼物分成收益':'Quà tặng kim cương: Phát sóng trực tiếp nhận quà tặng kim cương chia lợi nhuận',
    '金币礼物：本场直播收到金币礼物分成收益':'Kim tệ lễ vật: Buổi phát sóng trực tiếp nhận được kim tệ lễ vật chia làm lợi nhuận', 
    '其他：累计本场直播获得的游戏、门票或计时分成收益，总收益（钻石）=钻石礼物+金币礼物+其他':'Khác: Tích lũy thu nhập từ trò chơi, vé hoặc chia sẻ thời gian thu được trực tiếp này, tổng thu nhập (kim cương)=quà tặng kim cương+quà tặng tiền xu+khác', 
    '注意：收到的金币在结束直播时统一兑换为钻石':'Lưu ý: Đồng tiền vàng nhận được sẽ được đổi thành kim cương khi kết thúc chương trình phát sóng trực tiếp',
    '关闭':'Đóng cửa',

    '充值方式':'Cách nạp tiền',
    '确认':'Xác nhận',
    
    '订单确认中，请稍等...':'Đơn đặt hàng đang được xác nhận, vui lòng chờ một chút...',
    '请重新登录':'Vui lòng đăng nhập lại',
    
    '请在APP发布视频':'Vui lòng đăng video lên app',
    '请在APP开启直播':'Xin hãy phát sóng trực tiếp trên APP',

    '请绑定提款方式': 'Vui lòng ràng buộc phương thức rút tiền',
    '银行名称不能为空': 'Tên ngân hàng không được để trống',
    '账户名称不能为空': 'Tên tài khoản không được để trống',
    '验证码不能为空': 'Mã xác minh không được để trống',
    '请输入钻石数量': 'Vui lòng nhập số lượng kim cương',
    '提现失败': 'Rút tiền thất bại',

}
