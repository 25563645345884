import Vue from "vue";
import VueI18n from "vue-i18n";
import zh_cn from './zh-cn';
import zh_tw from './zh-tw';
import en from './en';
import vi from './vi';
 
Vue.use(VueI18n); // 全局挂载
 
export const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "en", // 从localStorage中获取 默认英文
  messages: {
    zh_cn,
    zh_tw,
    en,
    vi,
  }
});
 
export default i18n;

//注册i18n实例并引入语言文件
// import { createI18n } from 'vue-i18n';
// import zh_cn from './zh-cn';
// import zh_tw from './zh-tw';
// import en from './en';
// import vi from './vi';
// const i18n = createI18n({
//   locale: localStorage.getItem('language') || 'en', // 默认语言设置
//   legacy: false, // 必须设置为 false 以支持 Vue 3
//   messages: {
//     zh_cn,
//     zh_tw,
//     en,
//     vi,
//   },
// });

// export {i18n}

// export const setupI18n = {
//   install(app: App) {
//     app.use(i18n);
//   },
// };
