/**
 * 注册全局组件
 * 命名规则：驼峰标识
 */
//vant实现的下拉上拉组件
import vantCommonList from './vantCommonList/index.vue';
// 序列loading
import loadingAnimation from './loadingAnimation/index.vue';
import videoPaly from './videoPaly/index.vue';

const components = [
  vantCommonList, 
  loadingAnimation, 
  videoPaly
];

export default {
  install(Vue) {
    components.map((component) => {
      Vue.component(component.name, component);
    });
  },
};
